import axios from "axios"
import HttpStatus from "http-status-codes"

import Log from "../logging/Log.jsx"

import { BACKEND_ROOT_URL, AUTHMODULE_URL, TREATMENTS_URL } from "./api.js"

export const AUTHENTICATION_REQUEST = "AUTHENTICATION_REQUEST"
export const AUTHENTICATED = "AUTHENTICATED"
export const UNAUTHENTICATED = "UNAUTHENTICATED"
export const AUTHENTICATION_ERROR = "AUTHENTICATION_ERROR"
export const PATIENTS_REQUEST = "PATIENTS_REQUEST"
export const PATIENTS = "PATIENTS"
export const FETCH_ONE_PATIENT = "FETCH_ONE_PATIENT"
export const START_LOADING_ONE_BY_ONE = "START_LOADING_ONE_BY_ONE"
export const UNFULL_DATA = "UNFULL_DATA"
export const TOKEN_TO_PATIENTS = "TOKEN_TO_PATIENTS"
export const DATA_RETRIEVAL_ERROR = "DATA_RETRIEVAL_ERROR"
export const SEARCH_PATIENTS = "SEARCH_PATIENTS"

export const NOTIFY = "NOTIFY"
export const END_NOTIFY = "END_NOTIFY"

// Types

export const FETCH_PATIENTS = "FETCH_PATIENTS"  // Not in use
export const POST_PATIENTS = "POST_PATIENTS"

export const POST_PATIENTDEVICE = "POST_PATIENTDEVICE"
export const DELETE_PATIENTDEVICE = "DELETE_PATIENTDEVICE"

export const FETCH_SESSIONPROTOCOLS = "FETCH_SESSIONPROTOCOLS"
export const POST_SESSIONPROTOCOLS = "POST_SESSIONPROTOCOLS"
export const FETCH_PROTOCOL_SCHEMA = "FETCH_PROTOCOL_SCHEMA"
export const CLEAR_SCHEMA_INFO = "CLEAR_SCHEMA_INFO"

export const FETCH_DEVICEPROTOCOLS = "FETCH_DEVICEPROTOCOLS"
export const POST_DEVICEPROTOCOLS = "POST_DEVICEPROTOCOLS"
export const CLEAR_DEVICEPROTOCOLS = "CLEAR_DEVICEPROTOCOLS"

export const FETCH_SESSIONS = "FETCH_SESSIONS"
export const POST_SESSIONS = "POST_SESSIONS"
export const DELETE_SESSIONS = "DELETE_SESSIONS"

export const FETCH_LANGUAGES = "FETCH_LANGUAGES"
export const POST_LANGUAGES = "POST_LANGUAGES" // Not used, just for the sake of interoperability

export const FETCH_DEVICES = "FETCH_DEVICES"
export const POST_DEVICES = "POST_DEVICES"

export const FETCH_EEG_LINK = "FETCH_EEG_LINK"
export const REMOVE_EEG_LINK = "REMOVE_EEG_LINK"

export const FETCH_CSV_LINK = "FETCH_CSV_LINK"
export const REMOVE_CSV_LINK = "REMOVE_CSV_LINK"

// Statuses

export const _REQUESTED = "STATUS_REQUESTED"
export const _ERROR = "STATUS_ERROR"
export const _SUCCESS = "STATUS_SUCCESS"
export const _CLEAR = "STATUS_CLEAR"

export function pushNotification(message) {
  return {
    type: NOTIFY,
    payload: message
  }
}

export function notificationHidden() {
  return {
    type: END_NOTIFY
  }
}

export function authenticate(email, password) {
  Log.info("Authenticating!", email, password)

  var authUrl = BACKEND_ROOT_URL + AUTHMODULE_URL + "login"
  var meUrl = BACKEND_ROOT_URL + TREATMENTS_URL + "users/me"
  const meConfig = {}

  return async dispatch => {
    dispatch({
      type: AUTHENTICATION_REQUEST,
      payload: { email }
    })

    try {
      const authRes = await axios.post(authUrl, { email, password })
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${authRes.data.auth_token}`

      const meRes = await axios.get(meUrl, meConfig)
      const userData = Object.assign(authRes.data, meRes.data.users)
     
      dispatch({
        type: AUTHENTICATED,
        payload: userData
      })
    } catch (error) {
      __handleError(AUTHENTICATION_ERROR, dispatch, error)
    }
  }
}

export function deauthenticate() {
  Log.info("Deauthenticating!")

  var url = BACKEND_ROOT_URL + AUTHMODULE_URL + "logout"
  Log.info("URL", url)

  return async (dispatch, getState) => {
    if (__authenicateCall(dispatch, getState())) {
      try {
        const res = await axios.post(url)
        dispatch({
          type: UNAUTHENTICATED
        })
      } catch (error) {
        __handleError(DATA_RETRIEVAL_ERROR, dispatch, error)
      }
    }
  }
}

export function getPatients() {
  Log.info("Requesting patients")

  const url = BACKEND_ROOT_URL + TREATMENTS_URL + "patients"
  const config = {    
    headers: {
      "X-Fields":
        "{id, code, lang, devices{id, mac, model}}"
    }
  }

  return async (dispatch, getState) => {
    if (__authenicateCall(dispatch, getState())) {
      dispatch({ type: PATIENTS_REQUEST })

      try {
        const res = await axios.get(url, config)
        dispatch({ type: PATIENTS, payload: res.data })
      } catch (error) {
        __handleError(DATA_RETRIEVAL_ERROR, dispatch, error)
      }
    }
  }
}

export function getOnePatient(patient) {
  Log.info("Requesting patient:", patient)

  const fullUrl = BACKEND_ROOT_URL + TREATMENTS_URL + "patient/" + patient.id
  const config = {    
    headers: {
      "X-Fields":
        "{id, code, lang, devices{id, mac, model}, treatments{id, start_date, end_date, sessions{id, session_date, session_period_start, session_period_end, sessionprotocol{name}, problems{types, acute_events}, time_compliance, eeg_recordings[{id, timestamp, step}]}, time_compliance }}"
    }
  }

  return __fetch(fullUrl, config, FETCH_ONE_PATIENT)
}

export function startLoadingOneByOne() {
  return {
    type: START_LOADING_ONE_BY_ONE
  }
}

export function setUnfullData() {
  return {
    type: UNFULL_DATA
  }
}

export function saveTokenToPatiens(token) {
  return {
    type: TOKEN_TO_PATIENTS, payload: token
  }
}

export function searchPatients(searched) {
  return {
    type: SEARCH_PATIENTS, payload: searched
  }
}

export function addPatient(code, lang) {
  Log.info("Adding a patient:", { code, lang })

  const fullUrl = BACKEND_ROOT_URL + TREATMENTS_URL + "patient"
  const config = {
    headers: {
      "X-Fields":
        "{id, code, lang, devices{id, mac, model}, treatments{id, start_date, end_date, sessions{id}, time_compliance}}"
    }
  }

  let object = {
    code,
    lang
  }

  return __post(fullUrl, object, POST_PATIENTS, config)
}

export function editPatient(patient) {
  Log.info("Editing a patient:", patient)

  const fullUrl = BACKEND_ROOT_URL + TREATMENTS_URL + "patient/" + patient.id
  const config = {
    headers: {
      "X-Fields":
        "{id, code, lang, devices{id, mac, model}, treatments{id, start_date, end_date, sessions{id}, time_compliance}}"
    }
  }

  let object = {
    code: patient.code,
    lang: patient.lang
  }

  return __post(fullUrl, object, POST_PATIENTS, config)
}

export function getTreatmentSessions(treatmentId) {
  Log.info("Requesting session protocols")

  const fullUrl =
    BACKEND_ROOT_URL +
    TREATMENTS_URL +
    "treatments/" +
    treatmentId +
    "/sessions"
  const config = {
    headers: {
      "X-Fields":        
        "{url, id, session_date, session_period_start, session_period_end, time_compliance, problems, sessionprotocol{id, name}, eeg_recordings[{id, timestamp, step}]}"
    }
  }

  return __fetch(fullUrl, config, FETCH_SESSIONS)
}

export function postTreatmentSession(
  treatmentId,
  sessionProtocolId,
  sessionDate,
  sessionPeriodStart = undefined,
  sessionPeriodEnd = undefined,
  doBatchSchedule
) {
  Log.info("Posting treatment session")

  const fullUrl =
    BACKEND_ROOT_URL +
    TREATMENTS_URL +
    "treatments/" +
    treatmentId +
    "/sessions"
  let object = {
    sessionprotocol_id: sessionProtocolId,
    session_date: sessionDate,
    session_period_start: sessionPeriodStart,
    session_period_end: sessionPeriodEnd,
    do_batch_schedule: doBatchSchedule
  }

  const meta = { treatmentId }

  return __post(fullUrl, object, POST_SESSIONS, {}, meta)
}

export function deleteTreatmentSession(treatmentId, sessionId) {
  Log.info("Deleting treatment session")

  const fullUrl =
    BACKEND_ROOT_URL +
    TREATMENTS_URL +
    "treatments/" +
    treatmentId +
    "/sessions/" +
    sessionId

  return __delete(fullUrl, {}, DELETE_SESSIONS, {})
}

export function getEegLink(eegId) {
  Log.info("Getting EEG link")

  const fullUrl =
    BACKEND_ROOT_URL +
    TREATMENTS_URL +
    "eeg/" +
    eegId
  const config = {
    headers: {
      "X-Fields":        
        "{id, timestamp, step, link}"
    }
  }
  return __fetch(fullUrl, config, FETCH_EEG_LINK)
}

export function removeEegLink() {
  return {
    type: REMOVE_EEG_LINK
  }
}

export function getCsvLink(sessionId) {
  Log.info("Getting CSV link")

  const fullUrl =
    BACKEND_ROOT_URL +   
    TREATMENTS_URL + 
    "session/" +
    sessionId +
    "/events?type=NO_TELEMETRIES&format=CSV"
  const config = {
    headers: {
      "X-Fields":        
        "{content, filename}"
    }
  }
  return __fetch(fullUrl, config, FETCH_CSV_LINK)
}

export function removeCsvLink() {
  return {
    type: REMOVE_CSV_LINK
  }
}

export function getSessionProtocols(id = undefined) {
  Log.info("Requesting session protocols")

  const fullUrl =
    BACKEND_ROOT_URL +
    TREATMENTS_URL +
    "sessionprotocols" +
    (id ? "/" + id : "")
  const config = {
    headers: { "X-Fields": "{url, id, name, schema_id, deviceprotocol{id}}" }
  }

  return __fetch(fullUrl, config, FETCH_SESSIONPROTOCOLS)
}

export function getProtocolSchema(schema_id, init_date) {
  Log.info("Requesting protocol's schema")

  const fullUrl =
    BACKEND_ROOT_URL +
    TREATMENTS_URL +
    `schedulingpreview?schedulingschema_id=${schema_id}&init_date=${init_date}`
  const config = {
    headers: { "X-Fields": "{scheduled_dates}" }
  }

  return __fetch(fullUrl, config, FETCH_PROTOCOL_SCHEMA)
}

export function clearSchemaInfo() {
  return {
    type: CLEAR_SCHEMA_INFO
  }
}

export function postSessionProtocol(deviceProtocolId, name) {
  Log.info("Posting session protocol")

  const fullUrl = BACKEND_ROOT_URL + TREATMENTS_URL + "sessionprotocols"
  const object = {
    deviceprotocol_id: deviceProtocolId,
    name
  }

  return __post(fullUrl, object, POST_SESSIONPROTOCOLS)
}

export function getDeviceProtocol(id) {
  Log.info("Requesting device protocols")

  const fullUrl = BACKEND_ROOT_URL + TREATMENTS_URL + "deviceprotocols/" + id
  const config = {
    headers: {
      "X-Fields":
        "{url, id, definition{name, duration, real_stimulation, ramp_up_duration, ramp_down_duration, stim_electrode, default_montage}, definition_multistep{name, duration, stim_electrode, steps{name, total_duration, real_stimulation, duration, ramp_up_duration, ramp_down_duration, default_montage, is_only_eeg}}}"
    }
  }

  return __fetch(fullUrl, config, FETCH_DEVICEPROTOCOLS)
}

export function postDeviceProtocol(xmlTemplate) {
  Log.info("Posting device protocol")

  const fullUrl = BACKEND_ROOT_URL + TREATMENTS_URL + "deviceprotocols"
  const object = {
    xml_template: xmlTemplate
  }

  return __post(fullUrl, object, POST_DEVICEPROTOCOLS)
}

export function patchDeviceProtocol(deviceProtocolId, patch) {
  Log.info("Patching device protocol", deviceProtocolId, "with patch", patch)
  const fullUrl =
    BACKEND_ROOT_URL + TREATMENTS_URL + "deviceprotocols/" + deviceProtocolId

  return __patch(fullUrl, patch, POST_DEVICEPROTOCOLS)
}

export function clearDeviceProtocol() {
  return {
    type: CLEAR_DEVICEPROTOCOLS,
    status: _CLEAR
  }
}

export function getDevices() {
  Log.info("Requesting devices")

  const fullUrl = BACKEND_ROOT_URL + TREATMENTS_URL + "devices"
  const config = {
    headers: { "X-Fields": "{id, mac, model, patient{id, code}}" }
  }

  return __fetch(fullUrl, config, FETCH_DEVICES)
}

export function assignDeviceToPatient(patientId, deviceId) {
  Log.info("Posting device-patient assignment")

  const fullUrl =
    BACKEND_ROOT_URL + TREATMENTS_URL + "patient/" + patientId + "/device"
  const object = {
    device_id: deviceId
  }
  const config = {
    headers: { "X-Fields": "{id, mac, model, patient{id, code}}" }
  }

  return __post(fullUrl, object, POST_PATIENTDEVICE, config)
}

export function unAssignDeviceFromPatient(patientId, deviceId) {
  Log.info("Executing device-patient unassignment for ", patientId, " deviceId: ", deviceId)

  const fullUrl =
    BACKEND_ROOT_URL + TREATMENTS_URL + "patient/" + patientId + "/device"
  const object = {
    device_id: deviceId
  }
  const config = {
    headers: { "X-Fields": "{id, mac, model, patient{id, code}}" }
  }

  return __delete(fullUrl, object, DELETE_PATIENTDEVICE, config)
}

export function getLanguages() {
  const fullUrl = BACKEND_ROOT_URL + TREATMENTS_URL + "languages"
  const config = {
    headers: { "X-Fields": "{id, name}" }
  }

  return __fetch(fullUrl, config, FETCH_LANGUAGES)
}

//////////

function __authenicateCall(dispatch, state) {
  let authenticated = true
  if (!axios.defaults.headers.common["Authorization"]) {
    const currentState = state
    Log.info("Current state:", currentState)
    if (
      currentState.authenticationState.authenticated === true &&
      currentState.authenticationState.token
    ) {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${currentState.authenticationState.token}`
      Log.info("Assigned authentication token from state to requests")
    } else {
      authenticated = false
      dispatch({ type: UNAUTHENTICATED })
    }
  }
  return authenticated
}

// Following https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253#file-axios-catch-error-js
function __handleError(type, dispatch, error) {
  let authenticationLost = false
  let payload = { data: {} }
  let apiError = true
  if (error.response) {
    Log.info("Error handler: 1")
    payload = error.response
    authenticationLost = payload.status === HttpStatus.UNAUTHORIZED
  } else if (error.request) {
    payload.data.message =
      "Connection problem. Check your Internet connectivity."
  } else {
    apiError = false
  }

  if (authenticationLost) dispatch({ type: UNAUTHENTICATED })
  else if (apiError === true) dispatch({ type, status: _ERROR, payload })
}

function __fetch(fullUrl, config, type) {
  return async (dispatch, getState) => {
    if (__authenicateCall(dispatch, getState())) {
      dispatch({ type, status: _REQUESTED })
      try {
        Log.info("Running GET:", fullUrl, config)
        const res = await axios.get(fullUrl, config)
        dispatch({ type, status: _SUCCESS, payload: res.data })
      } catch (error) {
        __handleError(type, dispatch, error)
      }
    }
  }
}

function __post(fullUrl, object, type, config = {}, meta = {}) {
  return async (dispatch, getState) => {
    if (__authenicateCall(dispatch, getState())) {
      dispatch({ type, status: _REQUESTED })
      try {
        Log.info("Running POST:", fullUrl, object, config)
        const res = await axios.post(fullUrl, object, config)
        dispatch({ type, status: _SUCCESS, payload: res.data, meta })
      } catch (error) {
        __handleError(type, dispatch, error)
      }
    }
  }
}

function __patch(fullUrl, patch, type, config = {}, meta = {}) {
  return async (dispatch, getState) => {
    if (__authenicateCall(dispatch, getState())) {
      dispatch({ type, status: _REQUESTED })
      try {
        Log.info("Running PATCH:", fullUrl, patch, config)
        const res = await axios.patch(fullUrl, patch, config)
        dispatch({ type, status: _SUCCESS, payload: res.data, meta })
      } catch (error) {
        __handleError(type, dispatch, error)
      }
    }
  }
}

function __delete(fullUrl, object, type, config = {}, meta = {}) {
  return async (dispatch, getState) => {
    if (__authenicateCall(dispatch, getState())) {
      dispatch({ type, status: _REQUESTED })
      try {
        Log.info("Running DELETE:", fullUrl, object, config)
        const res = await axios.delete(fullUrl, object, config)
        dispatch({ type, status: _SUCCESS, payload: res.data, meta })
      } catch (error) {
        __handleError(type, dispatch, error)
      }
    }
  }
}
