import {   
  PATIENTS_REQUEST,
  PATIENTS,
  FETCH_ONE_PATIENT,
  START_LOADING_ONE_BY_ONE,
  UNFULL_DATA,
  TOKEN_TO_PATIENTS,
  SEARCH_PATIENTS,
  POST_PATIENTS,
  POST_SESSIONS,
  DELETE_SESSIONS,
  POST_PATIENTDEVICE,
  DELETE_PATIENTDEVICE,
  UNAUTHENTICATED,
  _REQUESTED,
  _SUCCESS,
  _ERROR
} from "../actions";

import Log from '../logging/Log.jsx';

const initialState = {
  data:[],
  token: null,
  searched:[],
  isRequestingOnePatient: false,
  loadingOneByOne: false,
  fullData: false,
  isFetching: false,
  error: null,
  posted: false,
  deleted: false
};

// Initial state of array as we want to keep a list of the cities
export default function(state = initialState, action) {
    Log.info("Received action:", action.type);
    Log.info("Current state:", state);
    let patients = state.data;
    switch (action.type) {
        case PATIENTS_REQUEST:
          return { ...state, isFetching: true, posted: false, deleted: false, error: null };
        case PATIENTS:          
          Log.info("New patients data:", action.payload.patients);
          if (!action.payload.patients.length) {
            return { ...state, isFetching: false, fullData: true };
          } else {
            return { ...state, isFetching: false, data: action.payload.patients, posted: false, deleted: false, error: null };
          }
        case FETCH_ONE_PATIENT:          
          if (!state.loadingOneByOne) {
            break;
          } else if (action.status === _REQUESTED) {
            return { ...state, isRequestingOnePatient: true };
          } else if (action.status === _SUCCESS) {            
            let patient = action.payload.patient;
            Log.info("Loading patient:", patient);
            patient.fullData = true;
            const index = patients.findIndex(el => el.id === patient.id);
            patients[index] = patient;
            let allDataFull;
            if (patients[patients.length-1].fullData) {
              allDataFull = true;
            } else {
              allDataFull = false;
            }
            return { ...state, data: patients, isRequestingOnePatient: false, loadingOneByOne: !allDataFull, fullData: allDataFull };
          } else if (action.status === _ERROR) {
            Log.info("Upon action of type", action.type, "reducer received an error:", action.payload);
            return { ...state, error: action.payload };
          } else {
            return state;
          }
        case START_LOADING_ONE_BY_ONE:
          return { ...state, loadingOneByOne: true };
        case UNFULL_DATA:
          return { ...state, data:[], isRequestingOnePatient: false, loadingOneByOne: false, fullData: false };;
        case TOKEN_TO_PATIENTS:
          return { ...state, token: action.payload };
        case SEARCH_PATIENTS:            
          Log.info("Searched patients: ", action.payload);
          return { ...state, searched: action.payload };
        case POST_PATIENTS:
          if (action.status === _REQUESTED) {
            return { ...state, isFetching: true, posted: false, deleted: false, error: null };
          } else if (action.status === _SUCCESS) {            
            let newPatient = action.payload['patient'][0];
            const patientExistsIndex = patients.map(p => p.id).indexOf(newPatient.id);
            if (patientExistsIndex >= 0) {
              patients[patientExistsIndex].lang = newPatient.lang;
            } else {
              newPatient.fullData = true;
              patients.push(newPatient);
            };
            return { ...state, isFetching: false, data: patients, posted: newPatient.id, deleted: false, error: null };
          } else if (action.status === _ERROR) {
            return { ...state, isFetching: false, posted: false, deleted: false, error: action.payload };
          } else {
            return state;
          }
        case POST_PATIENTDEVICE:
          if (action.status === _REQUESTED) {
            return { ...state, isFetching: true, posted: false, deleted: false, error: null };
          } else if (action.status === _SUCCESS) {            
            for(let i = 0; i < action.payload.devices.length; i++) {
              if(action.payload.devices[i].patient !== null) {
                let patientId = action.payload.devices[i].patient.id;
                let patientDevice = action.payload.devices[i];
                patients = patients.map(patient => {                  
                  if (patient.id === patientId) {
                    patient.devices = [patientDevice];
                    Log.info(`Assigned device ${patientDevice.id} to patient ${patient.code}`); 
                  }
                  if (patient.devices && patient.devices[0].id === patientDevice.id && patient.id !== patientId) {
                    patient.devices = null;
                    Log.info(`Deleted device ${patientDevice.id} from patient ${patient.code}`); 
                  }
                  return patient;
                });
              }
            }            
            return { ...state, isFetching: false, data: patients, posted: true, deleted: false, error: null };
          } else if (action.status === _ERROR) {
            return { ...state, isFetching: false, posted: false, deleted: false, error: action.payload };
          } else {
            return state;
          }
        case DELETE_PATIENTDEVICE:
          if (action.status === _REQUESTED) {
            return { ...state, isFetching: true, posted: false, deleted: false, error: null };
          } else if (action.status === _SUCCESS) {
            const deviceId = action.payload.devices[0].id;            
            const index = patients.findIndex(el => el.devices && el.devices[0].id === deviceId);            
            patients[index].devices = null;
            Log.info(`Deleted device ${deviceId} from patient ${patients[index].code}`);            
            return { ...state, data: patients, deleted: true, error: null };
          } else if (action.status === _ERROR) {
            return { ...state, error: action.payload };
          } else {
            return state;
          }
        case POST_SESSIONS: // FIXME: Temporary hack
          if(action.status === _SUCCESS) {
            let newData = state.data;
            Log.info("Will add posted session to patient with meta:", action.meta);
            for(let x = 0; x < newData.length; x++) {
              let done = false;
              let patientData = newData[x];
              for(let j = 0; j < patientData.treatments.length; j++) {
                let treatmentData = patientData.treatments[j];
                if(treatmentData.id === action.meta.treatmentId) {
                  action.payload.sessions.forEach(item => {
                    treatmentData.sessions.push({id: item.id})
                  });                  
                  Log.info("Altering patients state - updated treatment:", treatmentData);
                  done = true;
                  break;
                }
              }
              if(done===true) break;
            }
            return { ...state, data: newData, token: null };
          } else {
            return state;
          }
        case DELETE_SESSIONS:
          return { ...state, token: null };
        case UNAUTHENTICATED:
          return initialState;
        default:
          return state;
    }
}
