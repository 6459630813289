import { FETCH_PROTOCOL_SCHEMA, CLEAR_SCHEMA_INFO, _SUCCESS, _REQUESTED, _ERROR } from "../actions";

import Log from "../logging/Log.jsx";

const initialState = {  
  dates: null,
  isRequesting: false,
  error: null,
};

export default function(state = initialState, action) {
  Log.info("Received action:", action.type);
  Log.info("Current state:", state);

  switch (action.type) {
    case FETCH_PROTOCOL_SCHEMA:        
      if (action.status === _REQUESTED) {
        return { ...state, isRequesting: true };
      } else {
        if (action.status === _SUCCESS) {
            const scheduledDates = JSON.parse(action.payload).scheduled_dates;            
          return {
            ...state,            
            dates: scheduledDates,
            isRequesting: false
          };
        } /* if (action.status === _ERROR) */ else {
          Log.info("Upon action of type", action.type, "reducer received an error:", action.payload);
          return { ...state, error: action.payload };
        }
      };
    case CLEAR_SCHEMA_INFO:
      return initialState;           
    default:
      return state;
  }
}

