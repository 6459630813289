import React from "react";

import Log from '../logging/Log.jsx';

import withStyles from "material-ui/styles/withStyles";
import ButtonStyled from "./ButtonStyled";
import PatientEditor from "../organisms/PatientEditor";
import { Modal } from "material-ui";

const styles = {
  root: {
    textAlign: "right"
  }
}

class MenuHomeStudyActions extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      addOpen: false
    };
  }

  componentDidUpdate() {
    Log.info("MenuPatientsActions updated. New state:", this.state)
  }

  renderAddPatientModal() {
    return (
      <Modal
        onClose={() => this.setState({addOpen: false})}
        open={this.state.addOpen}
      >
        <PatientEditor 
          onClose={() => this.setState({addOpen: false})}
        />
      </Modal>
    )
  }

  render() {
    const { classes } = this.props;

    return (
      <div>
        {this.renderAddPatientModal()}

        <div className={classes.root}>
          <ButtonStyled
            icon="AddCircleOutline"
            message="new subject"
            onClick={() => this.setState({addOpen: true})}
          />
        </div>
      </div>
    )
  }

}

export default withStyles(styles)(MenuHomeStudyActions);