import React, { Component, Children } from "react"

import Log from "../logging/Log.jsx"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"

import BigCalendar from "react-big-calendar"

import moment from "moment"

import compose from "recompose/compose"
// material-ui components
import withStyles from "material-ui/styles/withStyles"

// @material-ui/icons
import HeaderCard from "components/Cards/HeaderCard.jsx"
import PermContactCalendar from "@material-ui/icons/PermContactCalendar"

// core components
import CardCalendarSession from "../molecules/CardCalendarSession.jsx"

import buttonStyle from "assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx"

import { getTreatmentSessions, deleteTreatmentSession } from "../actions/index"

import { Modal } from "material-ui"
import TreatmentSchedulerSimple from "../organisms/TreatmentSchedulerSimple.jsx"

import { patientByCodeSelector } from "../selectors/patientSelectors"
import { sessionsByPatientSelector } from "../selectors/sessionSelectors"
import ModalConfirmation from "../molecules/ModalConfirmation"
import LayoutSessionOverview from "../molecules/LayoutSessionOverview.jsx"

BigCalendar.setLocalizer(BigCalendar.momentLocalizer(moment))

const today = moment().format("YYYY-MM-DD")

const CURRENT_DATE = moment().toDate()

// example implementation of a wrapper
const ColoredDateCellWrapper = ({ children, value }) => {
  const newElement = React.cloneElement(children, {
    style: {
      height: "300px",
      borderType: "solid",
      borderColor: value === CURRENT_DATE ? "orange" : "black",
      backgroundColor: value !== CURRENT_DATE ? "#EEEEEE" : "#FFFFFF",
      ...children.props.style
    }
  })
  return newElement
}

export class HistorySessions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      events: [],
      alert: null,
      addSessionOpen: false,
      viewFutureSessionOpen: false,
      viewPastSessionOpen: false
    }
    this.hideAlert = this.hideAlert.bind(this)
    this.ModalViewFutureSession = this.ModalViewFutureSession.bind(this)
    this.ModalViewPastSession = this.ModalViewPastSession.bind(this)
    this.hideModalViewFutureSession = this.hideModalViewFutureSession.bind(this)
    this.hideModalViewPastSession = this.hideModalViewPastSession.bind(this)
  }

  componentDidMount() {
    const treatments = this.props.patient.treatments
    if (Array.isArray(treatments) && treatments.length > 0) {
      this.props.getTreatmentSessions(this.props.patient.treatments[0].id)
    }
  }

  selectedEvent(event) {
    if (event.color === "default")
      this.setState({ viewFutureSessionOpen: event })
    else this.setState({ viewPastSessionOpen: event.id })
  }

  addNewEventAlert(slotInfo) {
    let lastDatesSelected = slotInfo.slots

    Log.info(lastDatesSelected)

    if (moment(lastDatesSelected[0]).format("YYYY-MM-DD") >= today) {
      Log.info("Setting addSessionOpen to true", slotInfo)
      this.setState({ addSessionOpen: true, lastDatesSelected })
    }
  }

  addNewEvent(e, slotInfo) {
    var newEvents = this.state.events
    newEvents.push({
      title: e,
      start: slotInfo.start,
      end: slotInfo.end
    })
    this.setState({
      alert: null,
      events: newEvents
    })
  }

  hideAlert() {
    this.setState({
      addSessionOpen: false,
      alert: null
    })
  }

  hideModalViewFutureSession() {
    this.setState({ viewFutureSessionOpen: false })
  }

  hideModalViewPastSession() {
    this.setState({ viewPastSessionOpen: false })
  }

  ModalViewFutureSession() {
    let session = this.state.viewFutureSessionOpen
    if (session) {
      let dayDuration = session.allDay
        ? ""
        : "between " +
          moment(session.start).format("hA") +
          " and " +
          moment(session.end).format("hA")
      return (
        <ModalConfirmation
          content={
            <div>
              <p>Session protocol: {session.title}</p>
              <p>
                Scheduled for {moment(session.start).format("MMMM Do YYYY")}{" "}
                {dayDuration}
              </p>
            </div>
          }
          dangerText="Remove this session"
          onDanger={this.onSessionRemove.bind(this, session)}
          xClose={true}
          onCancel={this.hideModalViewFutureSession}
        />
      )
    } else {
      return ""
    }
  }

  ModalViewPastSession() {
    let sessionId = this.state.viewPastSessionOpen
    if (sessionId) {
      const sessions = this.props.sessions.filter(
        session => session.id === sessionId
      )
      if (sessions.length === 1) {
        const session = sessions[0]
        const patientCode = this.props.patient.code
        return (
          <ModalConfirmation
            content={
              <LayoutSessionOverview props={{ ...session, patientCode }} />
            }
            xClose={true}
            onCancel={this.hideModalViewPastSession}
          />
        )
      }
    } else {
      return ""
    }
  }

  onSessionRemove(event) {
    Log.info("Will remove the session", event)
    this.props.deleteTreatmentSession(event.treatmentId, event.id)
    this.hideModalViewFutureSession()
  }

  treatmentSessionToEvent = treatmentSession => {
    Log.info("Converting treatment session", treatmentSession, "to event")

    let title = treatmentSession.sessionprotocol.name

    let id = treatmentSession.id
    let treatmentId = treatmentSession.treatmentId

    let start, end, allDay
    if (treatmentSession.sessionPeriodStart) {
      allDay = false
      start = moment(
        treatmentSession.sessionDate + " " + treatmentSession.sessionPeriodStart
      ).toDate()
      end = moment(
        treatmentSession.sessionDate + " " + treatmentSession.sessionPeriodEnd
      ).toDate()
    } else {
      allDay = true
      start = moment(treatmentSession.sessionDate).toDate()
      end = moment(treatmentSession.sessionDate).toDate()
    }

    Log.info("Start - end:", start, end)

    let executed = Math.floor(treatmentSession.timeCompliance[0] / 60)
    let duration = Math.floor(
      (treatmentSession.timeCompliance[0] +
        treatmentSession.timeCompliance[1] +
        treatmentSession.timeCompliance[2]) /
        60
    )

    let problem =
      treatmentSession.problems &&
      ((treatmentSession.problems.acute_events &&
        treatmentSession.problems.acute_events.length > 0) ||
        (treatmentSession.problems.types &&
          treatmentSession.problems.types.length > 0))
        ? true
        : false

    let color
    // TODO: More meaningful data could be received on that from API (unstarted, started, finished_partially, finished)
    if (treatmentSession.sessionDate > today) color = "default"
    else if (treatmentSession.sessionDate === today && executed === 0)
      color = "default"
    else {
      if (executed >= duration - 1) color = "green"
      else if (executed >= 1) color = "orange"
      else color = "red"
    }

    return {
      id,
      treatmentId,
      title,
      allDay,
      start,
      end,
      color,
      executed,
      duration,
      problem
    }
  }

  eventColors(event, start, end, isSelected) {
    var backgroundColor = "event-"
    event.color
      ? (backgroundColor = backgroundColor + event.color)
      : (backgroundColor = backgroundColor + "default")
    return {
      className: backgroundColor
    }
  }

  components() {
    return {
      month: {
        event: CardCalendarSession
      }
    }
  }

  render() {
    Log.info("PATIENT:", this.props.patient)
    Log.info("Sessions:", this.props.sessions)

    const cardSubtitle =
      "Browse for past sessions" +
      (this.props.patient ? " for " + this.props.patient.code : "")
    const events = this.props.sessions.map(session =>
      this.treatmentSessionToEvent(session)
    )

    return (
      <div>
        <Modal
          onClose={() => this.hideAlert()}
          open={this.state.addSessionOpen}
        >
          <TreatmentSchedulerSimple
            onClose={() => this.hideAlert()}
            patient={this.props.patient}
            dates={this.state.lastDatesSelected}
          />
        </Modal>

        <this.ModalViewFutureSession />

        <this.ModalViewPastSession />

        {// NUMBER OF EVENTS/SESSIONS  Object.keys(events).length
        Log.info("LOGGER Events =>" + JSON.stringify(events))}

        <HeaderCard
          headerColor="orange"
          cardTitle={"Schedule History"}
          cardSubtitle={
            "History program treatment for " + this.props.patient.code
          }
          icon={PermContactCalendar}
          content={
            <BigCalendar
              events={events}
              length={365}
              defaultView="agenda"
              defaultDate={new Date(2018, 1, 1, 6)}
              views={["agenda", "month"]}
              showMultiDayTimes={false}
              components={{
                // dateCellWrapper: ColoredDateCellWrapper,
                month: { event: CardCalendarSession }
              }}
            />
          }
        />
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  const patient = patientByCodeSelector(state, props.patientCode)
  const sessions = sessionsByPatientSelector(state, patient)
  return { patient, sessions }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getTreatmentSessions, deleteTreatmentSession },
    dispatch
  )
}

export default compose(
  withStyles(buttonStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(HistorySessions)
