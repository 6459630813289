import React from 'react'
import Toolbar from 'react-big-calendar/lib/Toolbar'

import moment from 'moment'
const today = moment().format('YYYY-MM-DD')
export default class CalendarHeader extends Toolbar {
  componentDidMount() {
    const view = this.props.view
    console.log(view)
  }

  render() {
    return (
      <div className='rbc-toolbar'>
        <div className='rbc-btn-group'>
          <button type='button' onClick={() => this.navigate('PREV')}>
            Back
          </button>
          <button type='button' onClick={() => this.navigate('TODAY')}>
            Today
          </button>
          <button type='button' onClick={() => this.navigate('NEXT')}>
            Next
          </button>
        </div>
        <div className='rbc-toolbar-label'>{this.props.label}</div>
        <div className='rbc-btn-group'>
          <button type='button' onClick={this.view.bind(null, 'month')}>
            Calendar
          </button>
          <button type='button' onClick={this.view.bind(null, 'agenda')}>
            History
          </button>
        </div>
      </div>
    )
  }
}
