import React from "react"

import Log from "../logging/Log.jsx"

import AreaFileUpload from "../molecules/AreaFileUpload"

import withStyles from "material-ui/styles/withStyles"
import Select from "material-ui/Select"
import MenuItem from "material-ui/Menu/MenuItem"
import InputLabel from "material-ui/Input/InputLabel"
import FormControl from "material-ui/Form/FormControl"

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import ItemGrid from "components/Grid/ItemGrid.jsx"
import DeviceProtocolRepresentation from "../molecules/DeviceProtocolRepresentation"

import {
  primaryColor,
  successColor,
  warningColor
} from "assets/jss/material-dashboard-pro-react.jsx"

export const style = theme => ({
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  divider: {
    margin: `${theme.spacing.unit * 2}px 0`
  },
  ...customSelectStyle
})

const areaUploadStates = {
  DEFAULT: "default",
  ACTIVE: "active",
  UPLOADING: "uploading",
  SUCCESS: "success",
  ERROR: "error"
}

export class LayoutSelectSessionProtocol extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedSessionProtocol: "",
      filteredSessionProtocols: [],
      doBatchSchedule: false,
      schemaId: null,
      deviceProtocolToShow: null,
      awaitingDeviceProtocolCheck: false,
      deviceProtocolPatchToApply: null,
      areaUploadState: areaUploadStates.DEFAULT,
      // lastUploadSuccessful: null,
      lastUploadErrorMessage: "",
      uploadedProtocolName: null,
      uploadedProtocolOriginalName: null,
      electrodeModel: null,
      electrodeError: false,
      loading: true
    }

    this.presentProtocolDetails = this.presentProtocolDetails.bind(this)
    this.setDeviceProtocolToShow = this.setDeviceProtocolToShow.bind(this)
    this.handleNewDeviceProtocol = this.handleNewDeviceProtocol.bind(this)
    this.onDeviceProtocolPosted = this.onDeviceProtocolPosted.bind(this)
    this.onSessionProtocolPosted = this.onSessionProtocolPosted.bind(this)
    this.handleErroneousUpload = this.handleErroneousUpload.bind(this)
    this.renderAreaFileUpload = this.renderAreaFileUpload.bind(this)
    this.handleAreaUploadActive = this.handleAreaUploadActive.bind(this)
    this.handleAreaUploadUnactive = this.handleAreaUploadUnactive.bind(this)
    this.electrodeModelChosen = this.electrodeModelChosen.bind(this)
    this.filterProtocolsByName = this.filterProtocolsByName.bind(this)
  }

  isValidated() {
    const protocolValid = this.state.selectedSessionProtocol !== ""
    const electrodesValid =
      this.state.deviceProtocolHasElectrodeModel ||
      this.state.deviceProtocolPatchToApply !== null

    if (!electrodesValid) this.setState({ electrodeError: true })

    // TODO: Here feedback in case of wrong values

    return protocolValid && electrodesValid
  }
  getFormOutput() {
    return {
      sessionProtocolId: this.state.selectedSessionProtocol,
      doBatchSchedule:  this.state.doBatchSchedule,
      schemaId: this.state.schemaId,
      deviceProtocolPatch: {
        deviceProtocolId: this.state.deviceProtocolToShow,
        patch: this.state.deviceProtocolPatchToApply
      }
    }
  }

  async componentDidMount() {
    try {
      await this.props.getSessionProtocols()
      const filteredSessionProtocols = this.filterProtocolsByName()      
      this.setState({
        filteredSessionProtocols,        
        loading: false
      })
    } catch (error) {
      console.log(error)
    }     
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    Log.info(
      "Previous props:",
      prevProps,
      "New props:",
      this.props,
      "New state:",
      this.state
    )

    if (
      this.state.deviceProtocolToShowLoaded === false &&
      prevProps.deviceProtocols.isRequesting === true &&
      this.props.deviceProtocols.isRequesting === false &&
      this.props.deviceProtocols.posted !== null
    ) {
      if (this.props.deviceProtocols.error !== null) {
        this.onDeviceProtocolPostingFailed()
      } else {
        this.onDeviceProtocolPosted()
      }
    }

    if (
      prevProps.sessionProtocols.isRequesting === true &&
      this.props.sessionProtocols.isRequesting === false &&
      this.props.sessionProtocols.posted !== null
    ) {
      this.onSessionProtocolPosted()
    }

    if (
      prevProps.deviceProtocols.isRequesting === true &&
      this.props.deviceProtocols.isRequesting === false &&
      this.state.deviceProtocolToShow !== null &&
      Object.keys(this.props.deviceProtocols.byId).includes(
        this.state.deviceProtocolToShow.toString()
      )
    ) {
      const stimElectrode = this.props.deviceProtocols.byId[
        this.state.deviceProtocolToShow
      ].definition.stimElectrode
      this.setState({
        deviceProtocolToShowLoaded: true,
        deviceProtocolHasElectrodeModel: stimElectrode !== null
      })
    }
  }

  filterProtocolsByName() {
    const patientName = this.props.patient.code.toLowerCase()
    // const patientName = 'test'  // Change the name to test the filter feature    
    const sessionProtocolEntries = Object.keys(this.props.sessionProtocols.byId)    
    Log.info("Session protocols:", this.props.sessionProtocols.byId)
    Log.info("Session protocol IDs:", sessionProtocolEntries)    

    let matchingSessionProtocols = []

    for (var i = 0; i < sessionProtocolEntries.length; i++) {      
      const protocol = this.props.sessionProtocols.byId[sessionProtocolEntries[i]]      
      if (protocol.name.toLowerCase().includes(patientName)) {    
        Log.info("Adding protocol:", protocol)    
        matchingSessionProtocols.push(protocol)
      }      
    }

    if (matchingSessionProtocols.length === 0) {
      for (var i = 0; i < sessionProtocolEntries.length; i++) {         
        const protocol = this.props.sessionProtocols.byId[sessionProtocolEntries[i]]     
        Log.info("Adding protocol:", protocol) 
        matchingSessionProtocols.push(protocol)   
      }
    }
    
    Log.info("Matching session protocols:", matchingSessionProtocols)     
    
    return matchingSessionProtocols
  }

  onDeviceProtocolPostingFailed() {
    this.setState({
      areaUploadState: areaUploadStates.ERROR,
      lastUploadErrorMessage: this.props.deviceProtocols.error.data.message
    })
    Log.info(
      this.onDeviceProtocolPostingFailed.name,
      this.props.deviceProtocols.error
    )
  }

  onDeviceProtocolPosted() {
    const postedDeviceProtocolId = this.props.deviceProtocols.posted
    let name = this.props.deviceProtocols.byId[postedDeviceProtocolId]
      .definition.name
    const uniqueName = this.makeSessionProtocolNameUnique(name)
    this.setState({
      uploadedProtocolName: uniqueName,
      uploadedProtocolOriginalName: name
    })
    this.props.postSessionProtocol(postedDeviceProtocolId, uniqueName)
    Log.info(this.onDeviceProtocolPosted.name, "succeeded.")
  }

  padNumber(number, size) {
    var s = String(number)
    while (s.length < (size || 2)) {
      s = "0" + s
    }
    return s
  }

  makeSessionProtocolNameUnique(name) {
    let sessionProtocolEntries = Object.keys(this.props.sessionProtocols.byId)
    Log.info("Session protocols:", this.props.sessionProtocols.byId)
    Log.info("Session protocol IDs:", sessionProtocolEntries)
    let sessionProtocolNames = []
    for (var i = 0; i < sessionProtocolEntries.length; i++) {
      const entry = sessionProtocolEntries[i]
      Log.info("Adding protocol:", entry)
      const protocolName = this.props.sessionProtocols.byId[entry].name
      Log.info("Adding protocol name:", protocolName)
      sessionProtocolNames.push(protocolName)
    }
    const regex = new RegExp("^" + name + "(_\\d{3})?$")
    let matchingSessionProtocolNames = sessionProtocolNames.filter(n =>
      n.match(regex)
    )
    matchingSessionProtocolNames = matchingSessionProtocolNames.filter(
      (v, i, a) => a.indexOf(v) === i
    )
    if (matchingSessionProtocolNames.length === 0) return name
    else if (
      matchingSessionProtocolNames.length === 1 &&
      matchingSessionProtocolNames[0] === name
    )
      return name + "_001"
    else {
      matchingSessionProtocolNames.sort()
      Log.info("Sorted names:", matchingSessionProtocolNames)
      let lastMatchingProtocolName =
        matchingSessionProtocolNames[matchingSessionProtocolNames.length - 1]
      Log.info("Last match:", lastMatchingProtocolName)
      const lastMatchingProtocolNameOrderNo = parseInt(
        lastMatchingProtocolName.substr(lastMatchingProtocolName.length - 3)
      )
      return name + "_" + this.padNumber(lastMatchingProtocolNameOrderNo + 1, 3)
    }
  }

  onSessionProtocolPosted() {
    Log.info(this.onSessionProtocolPosted.name, "started.")
    if (this.props.sessionProtocols.posted === false) {
      this.setState({
        areaUploadState: areaUploadStates.ERROR,
        lastUploadErrorMessage: this.props.sessionProtocols.error.data.message
      })
      Log.info(this.onSessionProtocolPosted.name, "failed.")
    } else {
      const selectedSessionProtocol = this.props.sessionProtocols.posted.toString()
      this.setState({
        selectedSessionProtocol,
        areaUploadState: areaUploadStates.SUCCESS,
        lastUploadErrorMessage: ""
      })
      this.setDeviceProtocolToShow(
        this.props.sessionProtocols.byId[selectedSessionProtocol]
      )
      Log.info(this.onSessionProtocolPosted.name, "succeeded.")
    }
  }

  presentProtocolDetails() {
    Log.info("presentProtocolDetails when state is:", this.state)
    let protocol
    if (
      this.state.deviceProtocolToShow &&
      (protocol = this.props.deviceProtocols.byId[
        this.state.deviceProtocolToShow
      ])
    ) {
      Log.info("Will present protocol details")

      let isMultistep
      protocol.definitionMultistep != undefined &&
      protocol.definitionMultistep.steps != undefined &&
      protocol.definitionMultistep.steps.length > 0
        ? (isMultistep = true)
        : (isMultistep = false)
              
      return (       
        <DeviceProtocolRepresentation
          id="device-protocol-representation"
          {...protocol.definitionMultistep}
          personalizedMontage={null}
          isMultistep={isMultistep}
          showElectrodeError={this.state.electrodeError}
          onElectrodeModelChange={event => this.electrodeModelChosen(event)}
        />
      )
    } else {
      return ""
    }
  }

  electrodeModelChosen = event => {
    const patch = {
      definition: {
        stim_electrode: event
      }
    }
    this.setState({ deviceProtocolPatchToApply: patch })

    // this.setState({electrodeModel: event})
  }

  sessionProtocolSelectionChanged = event => {
    Log.info("Handling list pick:", event.target.name, event.target.value)
    this.setState({
      [event.target.name]: event.target.value,
      electrodeError: false
    })

    const chosenSessionProtocol = this.props.sessionProtocols.byId[
      event.target.value
    ]

    if (chosenSessionProtocol.schemaId !== null) {
      this.setState({ doBatchSchedule: true, schemaId: chosenSessionProtocol.schemaId })
    } else {
      this.setState({ doBatchSchedule: false, schemaId: null })
    }

    Log.info("Chosen protocol:", chosenSessionProtocol)
    this.setDeviceProtocolToShow(chosenSessionProtocol)
  }

  setDeviceProtocolToShow(chosenSessionProtocol) {    
    this.props.clearDeviceProtocol()
    if (chosenSessionProtocol.deviceprotocol) {
      let deviceprotocol_id = chosenSessionProtocol.deviceprotocol.id

      this.setState({ deviceProtocolToShow: deviceprotocol_id })

      const protocol = this.props.deviceProtocols.byId[deviceprotocol_id]
      Log.info("protocol", protocol)    
      this.props.getDeviceProtocol(deviceprotocol_id)  
      
       if (!protocol || !protocol.definition) {   
        this.setState({ deviceProtocolToShowLoaded: false })
      } else {
        this.setState({
          deviceProtocolToShowLoaded: true,
          deviceProtocolHasElectrodeModel:
            protocol.definition.stimElectrode !== null
        })
      }
    } else {
      console.error("No device protocol defined for the session protocol")
    }
  }

  handleNewDeviceProtocol(xmlTemplate) {
    this.setState({
      areaUploadState: areaUploadStates.UPLOADING,
      deviceProtocolToShowLoaded: false
    })
    this.props.postDeviceProtocol(xmlTemplate)
  }

  handleAreaUploadActive() {
    this.setState({ areaUploadState: areaUploadStates.ACTIVE })
  }

  handleAreaUploadUnactive() {
    this.setState({ areaUploadState: areaUploadStates.DEFAULT })
  }

  handleErroneousUpload() {
    this.setState({
      areaUploadState: areaUploadStates.ERROR,
      lastUploadErrorMessage:
        "The file type is not correct. Please, make sure to upload a protocol exported from NIC2."
    })
  }

  renderAreaFileUpload(props) {
    let color, content

    switch (this.state.areaUploadState) {
      case areaUploadStates.DEFAULT:
        color = "#D2D2D2"
        content = (
          <p>
            ...or upload from NIC2
            <br />a Starstim tCS stimulation protocol
          </p>
        )
        break
      case areaUploadStates.ACTIVE:
        color = primaryColor
        content = <p>Drop the protocol file here</p>
        break
      case areaUploadStates.UPLOADING:
        color = primaryColor
        content = <p>Uploading and verifying the protocol file...</p>
        break
      case areaUploadStates.SUCCESS:
        color = successColor
        if (
          this.state.uploadedProtocolName ===
          this.state.uploadedProtocolOriginalName
        ) {
          content = (
            <p>
              The protocol {this.state.uploadedProtocolName} uploaded
              successfully
            </p>
          )
        } else {
          content = (
            <p>
              A protocol with the name {this.state.uploadedProtocolOriginalName}{" "}
              already existed!
              <br />
              The uploaded protocol stored under the name{" "}
              {this.state.uploadedProtocolName}.
            </p>
          )
        }
        break
      case areaUploadStates.ERROR:
        color = warningColor
        content = (
          <p>
            The protocol could not be uploaded.
            <br />
            Reason: {this.state.lastUploadErrorMessage}
          </p>
        )
        break
    }

    Log.info(this.renderAreaFileUpload.name, "rendered color", color)

    return (
      <AreaFileUpload
        content={content}
        color={color}
        onUploadedFile={this.handleNewDeviceProtocol}
        onActive={this.handleAreaUploadActive}
        onUnactive={this.handleAreaUploadUnactive}
        onError={this.handleErroneousUpload}
      />
    )
  }

  setMenuItems (filteredSessionProtocols) {
    const { classes } = this.props
    const menuItems = filteredSessionProtocols.map(item => {
      return (
        <MenuItem
          key={item.id}
          value={item.id}
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected
          }}
        >
          {item.name}
        </MenuItem>
      )
    }) 
    return menuItems
  }

  render() {
    const { classes, sessionProtocols } = this.props
    const { selectedSessionProtocol, filteredSessionProtocols, loading } = this.state
    if (selectedSessionProtocol !== "") {
      Log.info(
        "Rendering LayoutSelectSessionProtocol with selected session protocol:",
        selectedSessionProtocol,
        sessionProtocols.byId[selectedSessionProtocol]
          .name
      )
    }

    let menuItems
    if (!loading) {
      menuItems = this.setMenuItems(filteredSessionProtocols)      
    }    

    return (
      <>
      {!loading && 
      <GridContainer justify="space-around" spacing={40}>
        <ItemGrid xs={12} sm={6}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Choose one of previously created...
            </InputLabel>
            <Select
              id="select-protocol"
              MenuProps={{
                className: classes.selectMenu
              }}
              classes={{
                select: classes.select
              }}
              value={selectedSessionProtocol}
              onChange={this.sessionProtocolSelectionChanged}
              inputProps={{
                name: "selectedSessionProtocol",
                id: "simple-select"
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem
                }}
                key={0}
              >
                Choose one from previously created...
              </MenuItem>
              
              {menuItems}
              
            </Select>
          </FormControl>

          {this.renderAreaFileUpload(this.props)}
        </ItemGrid>

        <ItemGrid xs={12} sm={6}>
          {this.presentProtocolDetails()}
        </ItemGrid>
      </GridContainer>
      }
      </>
    )
  }
}


export default withStyles(style)(LayoutSelectSessionProtocol)
