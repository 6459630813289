import { FETCH_EEG_LINK, REMOVE_EEG_LINK, _SUCCESS, _REQUESTED, _ERROR } from "../actions";

import Log from "../logging/Log.jsx";

const initialState = {
  id: null,
  timestamp: null,
  step: null,
  link: null,
  isRequesting: false,
  error: null,
  posted: false
};

export default function(state = initialState, action) {
  Log.info("Received action:", action.type);
  Log.info("Current state:", state);

  switch (action.type) {
    case FETCH_EEG_LINK:
      if (action.status === _REQUESTED) {
        return { ...state, isRequesting: true };
      } else {
        if (action.status === _SUCCESS) {
          return {
            ...state,
            id: action.payload.id,
            timestamp: action.payload.timestamp,
            step: action.payload.step,
            link: action.payload.link,
            isRequesting: false
          };
        } /* if (action.status === _ERROR) */ else {
          Log.info("Upon action of type", action.type, "reducer received an error:", action.payload);
          return { ...state, error: action.payload };
        }
      };
    case REMOVE_EEG_LINK:
      return initialState;     
    default:
      return state;
  }
}
