import React from 'react'
import PropTypes from 'prop-types'

import Log from '../logging/Log.jsx';

import HistorySessions from '../organisms/HistorySessions';

import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

import PatientAvatar from '../molecules/PatientAvatar.jsx'
import PatientDeviceAssignmentMenu from '../organisms/PatientDeviceAssignmentMenu';


const HistoryMain = ({ match }) => {
    Log.info("History Main")

    Log.info("Generating history ecosystem")
  return (
    <GridContainer justify="center">
      <ItemGrid xs={12} sm={12} md={6}>
        <PatientAvatar code={match.params.code}/>
      </ItemGrid>
      <ItemGrid xs={12} sm={12} md={6}>
        <div style={{textAlign: "right"}}>
          <PatientDeviceAssignmentMenu patientCode={match.params.code}/>
        </div>
      </ItemGrid>
      <ItemGrid xs={12} sm={12} md={12}>
        <HistorySessions patientCode={match.params.code}/>
      </ItemGrid>
    </GridContainer>
  )
}

HistoryMain.propTypes = {
  match: PropTypes.object.isRequired
};

export default HistoryMain;
