import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';

import { appStore, appStorePersistor } from './reducers';
import { PersistGate } from 'redux-persist/integration/react';

import indexRoutes from 'routes/index.jsx';

import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary.jsx'

import "assets/scss/material-dashboard-pro-react.css?v=1.1.1";
import "assets/styles.css";

const hist = createBrowserHistory();

ReactGA.initialize('UA-139265061-1');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(
  <Provider store={appStore}>
    <PersistGate loading={null} persistor={appStorePersistor}>
      <Router history={hist}>
        <ErrorBoundary>
          <Switch>
            {indexRoutes.map((prop, key) => {
              return (
                <Route path={prop.path} component={prop.component} key={key} />
              );
            })}
          </Switch>
        </ErrorBoundary>
      </Router>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
