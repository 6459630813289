import React from 'react'
import Log from '../logging/Log.jsx'

// react component for creating dynamic tables
import ReactTable from 'react-table'

import ComplianceTable from 'organisms/TableCompliance.jsx'

// @material-ui/icons
import Assignment from '@material-ui/icons/Assignment'
import Dvr from '@material-ui/icons/Dvr'
import Favorite from '@material-ui/icons/Favorite'
import Close from '@material-ui/icons/Close'

// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import ItemGrid from 'components/Grid/ItemGrid.jsx'
import IconCard from 'components/Cards/IconCard.jsx'
import HeaderCard from 'components/Cards/HeaderCard.jsx'
import IconButton from 'components/CustomButtons/IconButton.jsx'

import { dataTable } from 'variables/general.jsx'
import ButtonStyled from '../molecules/ButtonStyled'
import MenuPatientsActions from '../molecules/MenuHomeStudyActions'
import ReactGA from 'react-ga'

export const initializeReactGA = () => {
  ReactGA.initialize('UA-100557389-4')
}

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname })
  ReactGA.pageview(window.location.pathname)
}

const PatientsEcosystem = () => {
  // Google analytics -> Check production/development
  if (
    window.location.href == 'https://ne-portal-app.azurewebsites.net/studies'
  ) {
    initializeReactGA()
    logPageView()
  }

  Log.info('Generating patients ecosystem')
  return (
    <GridContainer>
      <ItemGrid xs={1} md={8} />
      <ItemGrid xs={12} md={4}>
        <MenuPatientsActions />
      </ItemGrid>
      <ItemGrid xs={12}>
        <HeaderCard
          headerColor='orange'
          cardTitle='Study Summary'
          cardSubtitle='Status of all subjects in Home Study'
          search={true}
          icon={Assignment}
          content={
            <div>
              <ComplianceTable />
            </div>
          }
        />
      </ItemGrid>
    </GridContainer>
  )
}

export default PatientsEcosystem
