import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import Search from "@material-ui/icons/Search";

import {
  getPatients,
  getOnePatient,
  startLoadingOneByOne,
  setUnfullData,
  searchPatients,
  saveTokenToPatiens,
} from "../../actions/index";

import CustomInput from "components/CustomInput/CustomInput.jsx";
import SearchButton from "components/CustomButtons/IconButton.jsx";
import Button from "components/CustomButtons/Button.jsx";

import mainTableHeaderLinksStyle from "assets/jss/material-dashboard-pro-react/components/mainTableHeaderLinksStyle";

function MainTableHeaderLinks({ ...props }) {
  const { classes } = props;
  const searchButton = classes.top + " " + classes.searchButton;

  const dispatch = useDispatch();
  const token = useSelector((state) => state.authenticationState.token);
  const patients = useSelector((state) => state.patients);
  let searched = useSelector((state) => state.patients.searched);
  const [query, setQuery] = useState("");

  const findSubject = (query) => {
    if (query !== "") {
      searched = patients.data.filter((item) => {
        return item.code.toLowerCase().includes(query.toLowerCase());
      });
    } else {
      searched = [];
    }
    dispatch(searchPatients(searched));
  };

  const handleChange = (e) => {
    e.preventDefault();
    e.target.value !== "" ? setQuery(e.target.value) : setQuery("");
    findSubject(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      findSubject(query);
    }
  };

  const onSearchButtonClicked = () => {
    findSubject(query);
  };

  const onBackButtonClicked = () => {
    if (searched && searched.length) {
      setQuery("");
      searched = [];
      dispatch(searchPatients(searched));
    }
  };

  const onResfreshButtonClicked = () => {
    dispatch(setUnfullData());
  };

  const getOneByOneData = async (data) => {
    data.sort((a, b) => {
      if (a.devices === null) return 1;
      else if (b.devices === null) return -1;
      else if (a.devices[0].mac > b.devices[0].mac) return 1;
      else return -1;
    });
    for (let i = 0; i < data.length; i++) {
      try {
        const el = data[i];
        await dispatch(getOnePatient(el));
      } catch (error) {
        console.log("Error in getOneByOneData: ", error);
      }
    }
  };

  useEffect(() => {
    if (patients.token !== token) {
      dispatch(saveTokenToPatiens(token));
      onResfreshButtonClicked();
    }
    if (!patients.data.length && patients.token === token) {
      dispatch(getPatients());
    }
    if (
      patients.data.length > 0 &&
      !patients.fullData &&
      !patients.loadingOneByOne
    ) {
      dispatch(startLoadingOneByOne());
      getOneByOneData(patients.data);
    }
    return function cleanup() {
      onBackButtonClicked();
    };
  }, [patients.data]);

  return (
    <>
      <Button
        round
        color="primary"
        size="sm"
        style={{ marginLeft: "15px" }}
        onClick={onResfreshButtonClicked}
      >
        Refresh Patient List
      </Button>
      <div className={classes.searchInTable}>
        {query !== "" && (
          <div className={classes.backButton}>
            <Button
              round
              color="primary"
              size="sm"
              onClick={onBackButtonClicked}
            >
              Back to all results
            </Button>
          </div>
        )}
        <div className={classes.searchInput}>
          <CustomInput
            formControlProps={{
              className: classes.top + " " + classes.search,
            }}
            inputProps={{
              placeholder: "Search",
              inputProps: {
                className: classes.searchInput,
                value: query,
                onChange: handleChange,
                onKeyPress: handleKeyPress,
              },
            }}
          />
          <SearchButton
            color="white"                   
            className={searchButton}
            onClick={onSearchButtonClicked}
          >
            <Search className={classes.searchIcon}/>
          </SearchButton>
        </div>
      </div>
    </>
  );
}

MainTableHeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(mainTableHeaderLinksStyle)(MainTableHeaderLinks);
