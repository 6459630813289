import React from "react"

import Log from "../logging/Log.jsx"

import withStyles from "material-ui/styles/withStyles"
import Divider from "material-ui/Divider"
import TextField from "material-ui/TextField"
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import ItemGrid from "components/Grid/ItemGrid.jsx"
import { Checkbox } from "material-ui"

import Check from "@material-ui/icons/Check"
import tasksStyle from "assets/jss/material-dashboard-pro-react/components/tasksStyle.jsx"

import moment from "moment"

const style = theme => ({
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  divider: {
    margin: `${theme.spacing.unit * 2}px 0`
  },
  ...customSelectStyle,
  ...tasksStyle
})

class LayoutScheduleTreatmentSessions extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      date: this.props.dates
        ? moment(this.props.dates[0]).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
      dateFixed: this.props.dates ? true : false,
      allDay: true,
      periodStart: "10:00",
      periodEnd: "18:00"
    }

    this.handleToggle = this.handleToggle.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  isValidated() {
    return true
  }

  getFormOutput() {
    const periodStart = this.state.allDay ? null : this.state.periodStart
    const periodEnd = this.state.allDay ? null : this.state.periodEnd
    return { date: this.state.date, periodStart, periodEnd }
  }

  handleToggle = () => event => {
    this.setState({ allDay: event.target.checked })
  }

  handleChange(event) {
    Log.info("2", event.target.name, event.target.value)
    var newValue = event.target.value

    if (
      event.target.name === "periodStart" ||
      event.target.name === "periodEnd"
    ) {
      if (newValue && newValue.length === 5) {
        newValue = newValue.substring(0, 3) + "00"
      }
    }
    if (event.target.name === "periodStart" && newValue.length != 5)
      newValue = "10:00"
    if (event.target.name === "periodEnd" && newValue.length != 5)
      newValue = "18:00"

    this.setState({ [event.target.name]: newValue })
  }

  render() {
    const { classes } = this.props
    return (
      <form className={classes.container} noValidate>
        <GridContainer spacing={40}>
          {/* <ItemGrid xs={12} sm={12}>
            <h4 className={classes.infoText}>Schedule Details</h4>
          </ItemGrid> */}

          <ItemGrid xs={12} sm={3}>
            Schedule:
          </ItemGrid>

          <ItemGrid xs={12} sm={9}>
            <TextField
              id="date"
              name="date"
              label="Session date"
              type="date"
              className={classes.textField}
              InputLabelProps={{
                shrink: true
              }}
              disabled={this.state.dateFixed}
              value={this.state.date}
              onChange={this.handleChange}
            />
          </ItemGrid>

          <ItemGrid xs={12} sm={12}>
            <Divider className={classes.divider} />
          </ItemGrid>

          <ItemGrid xs={12} sm={3}>
            Allowed daytime period:
          </ItemGrid>

          <ItemGrid xs={12} sm={3}>
            <TextField
              id="period_start"
              name="periodStart"
              label="Period start"
              type="time"
              className={classes.textField}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                step: 3600 // 1H
              }}
              disabled={this.state.allDay}
              value={this.state.periodStart}
              onChange={this.handleChange}
            />
            <br />
            Allow all day
            <Checkbox
              checked={this.state.allDay}
              onClick={this.handleToggle("allDay")}
              checkedIcon={<Check className={classes.checkedIcon} />}
              icon={<Check className={classes.uncheckedIcon} />}
              classes={{
                checked: classes.checked
              }}
              value="allDay"
            />
          </ItemGrid>

          <ItemGrid xs={12} sm={3}>
            <TextField
              id="period_end"
              name="periodEnd"
              label="Period end"
              type="time"
              className={classes.textField}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                step: 3600 // 1H
              }}
              disabled={this.state.allDay}
              value={this.state.periodEnd}
              onChange={this.handleChange}
            />
          </ItemGrid>
        </GridContainer>
      </form>
    )
  }
}

export default withStyles(style)(LayoutScheduleTreatmentSessions)
