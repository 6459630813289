import {  createSelector } from 'reselect'

import Log from '../logging/Log.jsx';

const getDevices = (state) => Object.keys(state.devices.byId).map(key => state.devices.byId[key])
const getPatientDevice = (patient, state) => { 
  if (patient && Array.isArray(patient.devices) && patient.devices.length > 0) {
    Log.info("getPatientDevice HERE")
    let deviceId = patient.devices[0].id;
    return state.devices.byId[deviceId];
  } 
  else {
    Log.info("getPatientDevice THERE")
    return null;
  }
}

const getDeviceTypeFromModelSymbol = (model) => {
  switch(model) {
    case 'STARSTIM_8_BT':
      return 'Starstim 8 BT'
    case 'STARSTIM_8_WIFI':
      return 'Starstim 8 WiFi'
    default:
      return model
  }
}

export const unassignedDevicesSelector = createSelector(
  [getDevices],
  (devices) => {
    return devices.filter(d => !d.patient).map(d => ({id: d.id, mac: d.mac, type: getDeviceTypeFromModelSymbol(d.model)}))
  }
)

export const assignedDevicesSelector = createSelector(
  [getDevices],
  (devices) => {
    return devices.filter(d => d.patient).map(d => ({id: d.id, mac: d.mac, type: getDeviceTypeFromModelSymbol(d.model), code: d.patient.code}))
  }
)

export const patientDeviceSelector = createSelector(
  [getPatientDevice],
  (device) => { if(device) return {id: device.id, mac: device.mac, type: getDeviceTypeFromModelSymbol(device.model)}; else return null; }
)