import React, { Component } from "react"
import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
import compose from "recompose/compose"
import { getEegLink, removeEegLink, getCsvLink, removeCsvLink } from "../actions/index"

import Chip from "material-ui/Chip"
import Avatar from "material-ui/Avatar"

import { withStyles } from "material-ui/styles"
import FileDownload from "@material-ui/icons/FileDownload"

import moment from "moment"

const styles = {
  iconAvatar: {
    background: "darkorange",
    color: "white"
  },
  iconAvatarSessionRecord: {
    background: "#2D9434",
    color: "white"
  },
  iconChip: {
    margin: "1%",
    cursor: "pointer",
    background: "orange",
    color: "white",
    fontWeight: "400",
    boxShadow:
      "0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12)"
  },
  iconChipSessionRecord: {
    margin: "1%",
    cursor: "pointer",
    background: "#4caf50",
    color: "white",
    fontWeight: "400",
    boxShadow:
      "0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12)"
  }
}

const problemsDictionary = {
  missed: "Missed",
  aborted_impedance: "Auto-aborted on high impedances",
  aborted_disconnect: "Auto-aborted on Necbox disconnection",
  aborted_patient: "Aborted by patient",
  pain: "Pain",
  electrode_sensation: "Sensation under electrode",
  cognitive_mood_chang: "Cognitive or mood changes",
  other: "Acute event other than listed"
}

  
class LayoutSessionOverview extends Component {     
   
  render() {
    
    const { patientCode, sessionDate, sessionprotocol, problems, timeCompliance, eegRecordings } = this.props.props
    const executed = moment.unix(timeCompliance[0]).format("mm [min] ss [s]")
    const total = moment
      .unix(timeCompliance[0] + timeCompliance[1] + timeCompliance[2])
      .format("mm [min] ss [s]")
  
    let acuteEvents
    if (problems.acuteEvents && problems.acuteEvents.length > 0)
      acuteEvents = problems.acuteEvents
        .map(p => problemsDictionary[p])
        .join(", ")
    else acuteEvents = "-"
  
    let incidents
    if (problems.types && problems.types.length > 0)
      incidents = problems.types.map(p => problemsDictionary[p]).join(", ")
    else incidents = "-" 

    let sessionEvents
    if (incidents === "Missed") {
      sessionEvents = false
    } else {
      sessionEvents = true
    }

    const download = 
    eegRecordings.length > 0 &&
    eegRecordings.map((item, index) => {         
      return item.step && (
        <Chip          
          onClick={() => handleClick(item.id, item.step)}          
          avatar={
            <Avatar style={styles.iconAvatar}>
              <FileDownload />
            </Avatar>
          }
          label={item.step}
          style={styles.iconChip}
          key={index}
        />
      )
    })   
    
    const handleClick = async (id, step) => {
      try {      
          await this.props.getEegLink(id)
          const link = this.props.eeg.link        
          const a = document.createElement("a")
          a.setAttribute("href", link)
          a.setAttribute("download", step)
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a) 
  
          this.props.removeEegLink()     
  
      } catch(error) {
        console.log(error)
      }    
    }

    

    const downloadCsv =           
        <Chip              
          onClick={() => handleClickCsv(this.props.props.id)}
          avatar={
            <Avatar style={styles.iconAvatarSessionRecord}>
              <FileDownload />
            </Avatar>
          }
          label="Session Record File (.csv)"
          style={styles.iconChipSessionRecord}              
    />

    const handleClickCsv = async (id) => {
      try {        
        await this.props.getCsvLink(id);
        const a = document.createElement("a");
        const file = new Blob([this.props.csv.content], {type: 'text/plain'});
        a.href = URL.createObjectURL(file);
        a.download = this.props.csv.filename;          
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);  
        this.props.removeCsvLink();  
  
      } catch(error) {
        console.log(error)
      }    
    }

    

    return (      
      
      <div>
        <h4>
          {patientCode}'s session on {sessionDate}
        </h4>
        <h6>Execution</h6>
        <p>Session protocol: {sessionprotocol.name}</p>
        <p>
          Executed: {executed} / {total}
        </p>
        <h6>Problems</h6>
        <p>Incidents: {incidents}</p>
        <p>Reported acute events: {acuteEvents}</p>
        {sessionEvents && (
          <>
           <h6>Download session record:</h6>
           {downloadCsv}
          </>
        )}  
        {eegRecordings.length > 0 && (
          <>
            <h6>Downloadable EEG files:</h6>
            {download}
          </>
        )}        
        
      </div>
    
    )

  }
} 


function mapStateToProps(state) {  
  return { 
    eeg: state.eeg,
    csv: state.csv, 
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getEegLink, removeEegLink, getCsvLink, removeCsvLink },
    dispatch
  )
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(LayoutSessionOverview)

