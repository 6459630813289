import React, { Component } from "react"

import Chip from "material-ui/Chip"
import Avatar from "material-ui/Avatar"

import { withStyles } from "material-ui/styles"

import moment from "moment"

import Warning from "@material-ui/icons/Warning"
import Error from "@material-ui/icons/Error"
import FileDownload from "@material-ui/icons/FileDownload"
import TimeLapse from "@material-ui/icons/Timelapse"

const styles = theme => ({
  root: {},
  chip: {
    height: 16,
    backgroundColor: "white",
    fontSize: 10,
    borderRadius: 8
  },
  avatar: {
    height: 16,
    width: 16,
    borderRadius: 8,
    backgroundColor: "white"
  },
  iconAvatar: {
    height: 16,
    width: 16,
    borderRadius: 8,
    backgroundColor: "red"
  },
  iconChip: {
    background: "transparent",
    width: 22,
    fontSize: 10,
    borderRadius: 8
  },
  error: {
    color: "white"
  },
  file: {
    height: 16,
    width: 16,
    borderRadius: 8,
    backgroundColor: "white",
    marginTop: 2
  },
  eeg: {},
  card: {}
})

class CardCalendarSession extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    
    const timestring = this.props.event.allDay
      ? "All day"
      : moment(this.props.event.start).format("hA") +
        " - " +
        moment(this.props.event.end).format("hA")

    const { classes } = this.props

    const chipDuration = this.props.event.duration ? (
      <Chip
        avatar={
          <Avatar className={classes.avatar}>
            <TimeLapse />
          </Avatar>
        }
        label={
          this.props.event.executed +
          "/" +
          this.props.event.duration +
          "minutes"
        }
        className={classes.chip}
      />
    ) : (
      ""
    )

    const chipError = this.props.event.problem ? (
      <Chip
        avatar={
          <Avatar className={classes.iconAvatar}>
            <Error className={classes.error} />
          </Avatar>
        }
        className={classes.iconChip}
      />
    ) : (
      ""
    )
    
    const download = this.props.event.eeg.length > 0 &&
       (
        <Chip
          avatar={
            <Avatar className={classes.avatar}>
              <FileDownload className={classes.file} />
            </Avatar>
          }
          className={classes.iconChip}
        />
      )


    return (
      <div className={classes.card}>
        {this.props.event.title}
        <br />
        <strong>{timestring}</strong>
        <br />
        <br />
        {download}
        {chipError} {chipDuration}
      </div>
    )
  }
}

export default withStyles(styles)(CardCalendarSession)
