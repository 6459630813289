import React from "react";
import PropTypes from "prop-types";

import withStyles from "material-ui/styles/withStyles";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx";

import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import PersonAdd from "@material-ui/icons/PersonAdd";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import Button from "components/CustomButtons/Button.jsx";
import SvgIcon from 'material-ui/SvgIcon';


function NEDevice(props) {
  return (
    <SvgIcon {...props}>
      <path d="M6.33.24C4.2.6 3.03 1.34 2.37 2.6c-.5 1.04-.5 1.27.24 6.94.41 3.24.83 6.08.91 6.34.06.24.54.86 1.04 1.33.88.86.91.86 4.54.86s3.66 0 4.55-.86c.5-.47.97-1.09 1.03-1.33.09-.26.5-3.1.91-6.31.65-5.2.71-5.93.33-6.82-.47-1.12-1.36-1.83-2.83-2.24A19.82 19.82 0 0 0 6.33.24zm7.26 1.27c.53.27 1.2.83 1.47 1.27.48.74.48 1.06-.23 6.82-.77 6.05-.77 6.08-1.71 6.9-.89.8-1.04.83-4.02.83-2.98 0-3.13-.03-4.01-.83-.92-.82-.95-.88-1.68-6.78-.83-6.47-.77-6.97.67-7.91C5.3 1 6.53.83 9.7.92c2.07.06 3.25.24 3.9.6z"/>
      <path d="M4.82 6.7c0 1.4.12 2.37.3 2.37.17 0 .3-.71.3-1.57V5.97l1.5 1.53c1.3 1.36 1.62 1.57 2.65 1.57.62 0 1.15-.15 1.15-.3 0-.18-.47-.3-1.03-.3-.82 0-1.03-.11-1.03-.58 0-.45.2-.6.7-.6.42 0 .83-.14.92-.29.12-.18.36-.12.62.15.71.68 2.1.56 2.63-.27.94-1.41-.65-3.07-2.19-2.24-.4.2-.62.2-.62 0 0-.15-.59-.3-1.32-.3H8.07v1.54l-.03 1.56-1.39-1.56a8.59 8.59 0 0 0-1.6-1.54c-.14 0-.23 1.07-.23 2.37z"/>           
    </SvgIcon>
  )
}


var componentList = {
  AddCircleOutline: AddCircleOutline,
  PersonAdd: PersonAdd,
  NEDevice: NEDevice
}

class ButtonStyled extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  Icon(props) {
    const { classes, icon } = props;
    const Icon = componentList[icon];
    const style = icon !== "NEDevice" ? classes.icons : classes.icons2;
    if(Icon) {
      return (
        <Icon className={style} />
      )
    }
  }

  render() {
    const { classes, icon, message, inactive, dropdown } = this.props;

    const color = inactive ? "default" : "primary";
    const buttonClasses = dropdown ? (classes.marginRight + " " + classes.paddingRight) : classes.marginRight;

    return (
      <Button
        color={color}
        round
        customClass={buttonClasses}
        onClick={this.props.onClick}
      >
        <this.Icon classes={classes} icon={icon}/> 
        {message} 
        {dropdown ? <KeyboardArrowDown className={classes.dropdown}/> : ""}
      </Button>
    )
  }

}

ButtonStyled.propTypes = {
  classes: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  inactive: PropTypes.bool,
  dropdown: PropTypes.bool
}

export default withStyles(buttonsStyle)(ButtonStyled);