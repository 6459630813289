import { AUTHENTICATED, AUTHENTICATION_ERROR, UNAUTHENTICATED, AUTHENTICATION_REQUEST } from "../actions";
import axios from 'axios';

import Log from '../logging/Log.jsx';

const initialState = { authenticated: false, token: null, accessLevel: null }

export default function(state = initialState, action) {
    Log.info("Received action:", action.type);
    Log.info("Current state:", state);
    switch (action.type) {
        case AUTHENTICATED:
            Log.info("action:", action);
            let fullname = [action.payload.title.trim(), action.payload.first_name.trim(), action.payload.last_name.trim()].join(' ');
            Log.info("FULLNAME", fullname);
            let iconUrl = action.payload.organization_icon;
            axios.defaults.headers.common['Authorization'] = `Bearer ${action.payload.auth_token}`;
            Log.info("Changed axios default headers:", axios.defaults.headers);
            let accessLevel = null;
            if (action.payload.access_level) {
                accessLevel = action.payload.access_level;
            } 
            return { ...state, authenticated: true, token: action.payload.auth_token, accessLevel, fullname, iconUrl, error: null };                      
        case AUTHENTICATION_ERROR:
            Log.info("Action", action);
            return { ...state, authenticated: false, error: action.payload, token: null, accessLevel: null };
        case UNAUTHENTICATED:
            delete axios.defaults.headers.common['Authorization'];
            Log.info("Changed axios default headers:", axios.defaults.headers);
            return { authenticated: false, token: null, error: null, accessLevel: null };
        case AUTHENTICATION_REQUEST:
            return { ...state, error: null };        
        default:
            return state;
    }

    return state;
}