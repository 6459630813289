import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import Log from '../../logging/Log.jsx'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { authenticate } from '../../actions/index'
import compose from 'recompose/compose'

// material-ui components
import withStyles from 'material-ui/styles/withStyles'
import InputAdornment from 'material-ui/Input/InputAdornment'

// @material-ui/icons
import Face from '@material-ui/icons/Face'
import Email from '@material-ui/icons/Email'
import LockOutline from '@material-ui/icons/LockOutline'

// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import ItemGrid from 'components/Grid/ItemGrid.jsx'
import LoginCard from 'components/Cards/LoginCard.jsx'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Danger from 'components/Typography/Danger.jsx'

import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx'

import ReactGA from 'react-ga'

export const initializeReactGA = () => {
  ReactGA.initialize('UA-100557389-4')
}

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname })
  ReactGA.pageview(window.location.pathname)
}

class LoginPage extends React.Component {
  constructor(props) {
    super(props)
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: 'cardHidden',
      email: '',
      password: '',
      signInSubmitted: false,
      userTyping: false
    }

    this.handleSignInSubmit = this.handleSignInSubmit.bind(this)
    this.onSignInButtonClicked = this.onSignInButtonClicked.bind(this)
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.onEmailChange = this.onEmailChange.bind(this)
    this.onPasswordChange = this.onPasswordChange.bind(this)
  }

  UNSAFE_componentWillMount() {
    // Google analytics -> Check production/development
    if (
      window.location.href ==
      'https://ne-portal-app.azurewebsites.net/pages/sign_in'
    ) {
      initializeReactGA()
      logPageView()
    }
  }

  componentDidMount() {
    Log.info('Props on component load:', this.props)
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    setTimeout(
      function() {
        this.setState({ cardAnimaton: '' })
      }.bind(this),
      700
    )
  }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   Log.info("Error states on update:", this.state.error, this.props.authenticationState.error)
  //   if(this.state.error == null && this.props.authenticationState.error != null) {
  //     this.setState({error: this.props.authenticationState.error});
  //   }
  // }

  handleSignInSubmit = () => {
    this.props.authenticate(this.state.email, this.state.password)
    this.setState({ signInSubmitted: true, userTyping: false, error: null })
  }

  onSignInButtonClicked = () => {
    Log.info('Sign in button clicked!')
    this.handleSignInSubmit()
  }

  handleKeyPress = event => {
    if (event.key == 'Enter') {
      Log.info('Enter clicked!')
      this.handleSignInSubmit()
    }
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value })
    this.setState({ userTyping: true })
  }

  onPasswordChange(event) {
    this.setState({ password: event.target.value })
    this.setState({ userTyping: true })
  }

  renderErrorMessage() {
    Log.info(
      'Error message rendering:',
      this.props.authenticationState,
      this.state
    )

    if (
      this.state.signInSubmitted &&
      !this.state.userTyping &&
      this.props.authenticationState &&
      this.props.authenticationState.authenticated === false &&
      this.props.authenticationState.error !== null      
    ) {     
      if (this.props.authenticationState.error.data.message === "User does not exist.") {
        return <Danger>The email or password is incorrect.</Danger>
      }
      throw new Error(this.props.authenticationState.error.data.message);
    } 
  }

  render() {
    const { classes } = this.props
    return (
      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer justify='center'>
            <ItemGrid xs={12} sm={6} md={4}>
              <form>
                <LoginCard
                  customCardClass={classes[this.state.cardAnimaton]}
                  headerColor='orange'
                  cardTitle='Sign in to your institutional account'
                  footerAlign='center'
                  footer={
                    <div>
                      <Button
                        color='primaryNoBackground'
                        wd
                        size='lg'
                        onClick={this.onSignInButtonClicked}
                      >
                        Sign in
                      </Button>
                      {this.renderErrorMessage()}
                    </div>
                  }
                  content={
                    <div>
                      <CustomInput
                        labelText='Email'
                        id='email'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: this.state.email,
                          onChange: this.onEmailChange,
                          onKeyPress: this.handleKeyPress,
                          endAdornment: (
                            <InputAdornment position='end'>
                              <Email className={classes.inputAdornmentIcon} />
                            </InputAdornment>
                          )
                        }}
                      />
                      <CustomInput
                        labelText='Password'
                        id='password'
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: this.state.password,
                          onChange: this.onPasswordChange,
                          onKeyPress: this.handleKeyPress,
                          endAdornment: (
                            <InputAdornment position='end'>
                              <LockOutline
                                className={classes.inputAdornmentIcon}
                              />
                            </InputAdornment>
                          ),
                          type: 'password'
                        }}
                      />
                    </div>
                  }
                />
              </form>
            </ItemGrid>
          </GridContainer>
        </div>
      </div>
    )
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ authenticate }, dispatch)
}

export default compose(
  withStyles(loginPageStyle),
  connect(
    null,
    mapDispatchToProps
  )
)(LoginPage)
