import { combineReducers, createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native

import reduxThunk from 'redux-thunk';

import SignInReducer from './sign_in';
import PatientsReducer from './patients';
import DeviceProtocolsReducer from './deviceprotocols';
import SessionProtocolsReducer from './sessionprotocols';
import ProtocolSchemaReducer from './schema';
import SessionReducer from './sessions';
import DevicesReducer from './devices';
import LanguagesReducer from './languages';
import Notification from './notification';
import Eeg from './eeg';
import Csv from './csv';

const rootReducer = combineReducers({
   authenticationState: SignInReducer,
   patients: PatientsReducer,   
   deviceProtocols: DeviceProtocolsReducer,
   sessionProtocols: SessionProtocolsReducer,
   schema: ProtocolSchemaReducer,
   sessions: SessionReducer,
   devices: DevicesReducer,
   languages: LanguagesReducer,
   notification: Notification,
   eeg:Eeg,
   csv: Csv   
});

const persistConfig = {
   key: 'root',
   storage: storage,
   blacklist: ['sessions', 'notification']
 };


const persistedReducer = persistReducer(persistConfig, rootReducer);

const appStore = createStore(persistedReducer, composeWithDevTools(applyMiddleware(reduxThunk)));

const appStorePersistor = persistStore(appStore);

export { appStore, appStorePersistor };

export default rootReducer;