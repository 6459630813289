import React from 'react'
import PropTypes from 'prop-types'
import withStyles from "material-ui/styles/withStyles";

import AccountCircle from "@material-ui/icons/AccountCircle";
import Edit from "@material-ui/icons/Edit";
import PatientEditor from "../organisms/PatientEditor";
import { Modal } from "material-ui";

const avatarStyle = {
    parent: {
        marginBottom: "30px",
        height: "40px",
        alignItems: "center",
        display: "flex"
    },
    blable: {
        padding: "20px",
        verticalAlign: "middle"
    },
    icon: {
        width: "40px",
        height: "40px"
    },
    edit: {
        width: "35px",
        height: "35px",
        paddingLeft: "10px",
        cursor: "pointer"
    }
};

class PatientAvatar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          editOpen: false
        };
    }

    renderAddPatientModal(code) {
        return (
        <Modal
            onClose={() => this.setState({editOpen: false})}
            open={this.state.editOpen}
        >
            <PatientEditor code={code}
            onClose={() => this.setState({editOpen: false})}
            />
        </Modal>
        )
    }

    render() {
        let { classes, code } = this.props;

        return (
            <div>
                {this.renderAddPatientModal(code)}
                <h3 className={classes.parent}>
                    <AccountCircle className={classes.icon}/>
                    <span className={classes.blable}>{code}</span>
                    <Edit className={classes.edit}
                        onClick={() => this.setState({editOpen: true})}
                    />
                </h3>
            </div>

        )
    }

}

PatientAvatar.propTypes = {
    code: PropTypes.string.isRequired
}

export default withStyles(avatarStyle)(PatientAvatar);