import React, { Component } from 'react'
import Dropzone from 'react-dropzone'

import Log from '../logging/Log.jsx'

import FileUpload from '@material-ui/icons/FileUpload'

import withStyles from 'material-ui/styles/withStyles'
import { ThemeProvider } from 'styled-components'

const dropZoneBase = {
  width: '100%',
  height: '225px',
  paddingTop: '10%',
  paddingBottom: '10%',
  paddingLeft: '10%',
  paddingRight: '10%',
  borderRadius: '4px',
  boxSizing: 'border-box',
  margin: '20px 0 20px',
  border: '1px solid'
}

const styles = {
  dropZone: {
    ...dropZoneBase,
    borderColor: '#D2D2D2'
  },
  inTheZone: {
    textAlign: 'center',
    verticalAlign: 'middle'
  },
  icon: {
    width: '80px',
    height: '72px'
  }
}

class AreaFileUpload extends Component {
  constructor(props) {
    super(props)

    this.state = {
      name: null,
      ...props.initialState // As suggested in https://storybook.js.org/basics/faq/#can-i-modify-react-component-state-in-stories
    }
    this.onDrop = this.onDrop.bind(this)
  }

  prettify(str) {
    return str
      .split(new RegExp('-|_'))
      .map(function capitalize(part) {
        return part.charAt(0).toUpperCase() + part.slice(1)
      })
      .join(' ')
      .split('.')[0]
  }

  onDrop(accepted, rejected) {
    Log.info('Accepted', accepted)
    Log.info('Rejected', rejected)

    // If accepted, send device protocol to the server
    // If verified there and values returned, show a field to name the procotol

    if (accepted && accepted.length > 0) {
      const fileContent = this.handleUploadedFileContent(accepted[0])
    } else {
      this.props.onError()
    }
  }

  handleUploadedFileContent = async inputFile => {
    try {
      const fileContents = await createPromise(inputFile)
      this.props.onUploadedFile(fileContents)
    } catch (e) {
      console.warn(e)
    }

    function createPromise(inputFile) {
      const temporaryFileReader = new FileReader()

      return new Promise((resolve, reject) => {
        Log.info(0)
        temporaryFileReader.onerror = () => {
          Log.info(1)
          temporaryFileReader.abort()
          reject(new DOMException('Problem parsing input file.'))
        }

        temporaryFileReader.onload = () => {
          Log.info(2)
          resolve(temporaryFileReader.result)
        }
        Log.info('Input file:', inputFile)
        temporaryFileReader.readAsText(inputFile)
      })
    }
  }

  render() {
    const { classes } = this.props
    return (
      <div className='dropzone'>
        <Dropzone
          onDrop={this.onDrop}
          multiple={false}
          className={classes.dropZone}
          style={{ borderColor: this.props.color }}
          accept='.xml,.txt,.neprot'
          onDragEnter={this.props.onActive}
          onDragLeave={this.props.onUnactive}
        >
          <div className={classes.inTheZone}>
            <FileUpload
              className={this.props.classes.icon}
              style={{ color: this.props.color }}
            />
            {this.props.content}
          </div>
        </Dropzone>
      </div>
    )
  }
}

export default withStyles(styles)(AreaFileUpload)
