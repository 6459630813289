import React, { Component } from 'react';

import Chip from 'material-ui/Chip';
import Avatar from 'material-ui/Avatar';

import { withStyles } from 'material-ui/styles';

import moment from 'moment';

import Warning from '@material-ui/icons/Warning';
import Error from '@material-ui/icons/Error';
import TimeLapse from '@material-ui/icons/Timelapse';

const styles = theme => ({
  root: {},
  chip: {
    height: 16,
    backgroundColor: 'white',
    fontSize: 10,
    borderRadius: 8
  },
  avatar: {
    height: 16,
    width: 16,
    borderRadius: 8,
    backgroundColor: 'white'
  },
  iconAvatar: {
    height: 16,
    width: 16,
    borderRadius: 8,
    backgroundColor: 'red'
  },
  iconChip: {
    height: 0,
    width: 0,
    fontSize: 10,
    borderRadius: 8
  },
  error: {
    color: 'white'
  },
  card: {
    height: '30px'
  },
  font: {
    fontSize: '1.1em',
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 300,
    lineHeight: '1.5em'
  }
});

class CalendarSessions extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const timestring = this.props.event.allDay
      ? 'All day'
      : moment(this.props.event.start).format('hA') +
        ' - ' +
        moment(this.props.event.end).format('hA');

    const { classes } = this.props;

    return <div className={classes.card}>{timestring}</div>;
  }
}

export default withStyles(styles)(CalendarSessions);
