import React, {Component} from 'react';

import Log from '../logging/Log.jsx';

import withStyles from "material-ui/styles/withStyles";
import Select from "material-ui/Select";
import MenuItem from "material-ui/Menu/MenuItem";
import InputLabel from "material-ui/Input/InputLabel";
import FormControl from "material-ui/Form/FormControl";
import Divider from 'material-ui/Divider';
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

import tasksStyle from "assets/jss/material-dashboard-pro-react/components/tasksStyle.jsx";

import moment from 'moment';

const style = theme => ({
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
  },
  divider: {
    margin: `${theme.spacing.unit * 2}px 0`,
  },
  ...customSelectStyle,
  ...tasksStyle
});

class LayoutEditPatientBasicData extends React.Component {
  constructor(props) {
    super(props);

    Log.info("Creating LayoutEditPatientBasicData with props:", props)

    let lang = props.lang
    if (!lang) lang = 1

    this.state = {
      existingCodes: props.existingCodes,
      code: props.code,
      isEdit: props.code ? true : false,
      codeValid: null,
      lang: lang.toString(),
      langValid: null
    };

    this.handleToggle = this.handleToggle.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validate = this.validate.bind(this);
    this.isChanged = this.isChanged.bind(this);
  }

  getFormOutput() {
    const lang = parseInt(this.state.lang)
    return {code: this.state.code, lang}
  }

  isValidated() {
    let valid = this.validate("code") && this.validate("lang")
    let changed = this.isChanged("code") || this.isChanged("lang")

    return valid && changed;
  }

  handleToggle = name => event => {
    this.setState({ allDay: event.target.checked });
  }

  isChanged(field) {
    return this.state[field] != this.props[field]
  }

  handleChange(event) {
    Log.info(event.target)

    const field = event.target.name;
    const fieldValidField = field+"Valid";
    const value = event.target.value;
    let valid = true

    valid = this.validate(field, value)

    var stateUpdate = {}
    stateUpdate[fieldValidField] = valid
    stateUpdate[field] = value

    Log.info("Updating state:", stateUpdate)
    this.setState(stateUpdate)
  }

  // languageSelectionChanged = event => {
  //   Log.info("Handling list pick:", event.target);
  //   this.setState({ [event.target.name]: event.target.value });
  // };

  validate(field, value) {
    if (value === undefined) value = this.state[field]
    if (field === "code") {
      const codeFormatCorrect = (value.length >= 6 && value.length <= 20 && value.indexOf(' ') < 0)
      const codeNonRepeating = (this.props.existingCodes.indexOf(value) < 0)
      const codeUnchanged = (value === this.props[field])
      this.setState({repeatingCode: !codeNonRepeating})
      return codeFormatCorrect && (codeNonRepeating || codeUnchanged);
    }
    else if (field === "lang") {
      return true
    }
  }

  render() {
    const { classes } = this.props;

    const menuItems = Object.keys(this.props.languages.byId).map(id => {
      return (
        <MenuItem
          key={id}
          value={id}
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected
          }}
          >
          {this.props.languages.byId[id].name}
        </MenuItem>
      );
    });

    return (
      <form className={classes.container} noValidate>
        <GridContainer spacing={40}>

          <ItemGrid xs={12} sm={3}>
            Identification:
          </ItemGrid>

          <ItemGrid xs={12} sm={4}>
            <CustomInput
              success={this.state.codeValid===true}
              error={this.state.codeValid===false}
              labelText={
                <span>
                  Subject code
                </span>
              }
              helpText={
                  this.state.repeatingCode
                    ? "A patient with this code already exists!"
                    : "Use 6-20 characters without spaces"
              }
              formControlProps={{
                fullWidth: true
              }}
              disabled={this.state.isEdit}
              inputProps={{
                onChange: event => this.handleChange(event),
                type: "text",
                name: "code",
                value: this.state.code,
                disabled: this.state.isEdit
              }}
            />
          </ItemGrid>

          <ItemGrid xs={12} sm={12}>
            <Divider className={classes.divider} />
          </ItemGrid> 

          <ItemGrid xs={12} sm={3}>
            Personalization:
            <br></br>
            <small>Configures Home Interface</small>
          </ItemGrid>  

          <ItemGrid xs={12} sm={4}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="simple-select" className={classes.selectLabel} >
                Choose language...
              </InputLabel>
              <Select
                MenuProps={{
                  className: classes.selectMenu
                }}
                classes={{
                  select: classes.select
                }}
                value={this.state.lang}
                inputProps={{
                  name: "lang",
                  id: "simple-select",
                  onChange: event => this.handleChange(event),
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem
                  }}
                  key={0}
                >
                  Choose language...
                </MenuItem>
                {menuItems}
              </Select>
            </FormControl>
          </ItemGrid>

          {/* <ItemGrid xs={12} sm={12}>
            <Divider className={classes.divider} />
          </ItemGrid>        

          <ItemGrid xs={12} sm={12}>
            <small>We will enable storing of more contextual information about subjects in the future. Please, reach out to <a href="mailto:support@neuroelectrics.com">support@neuroelectrics.com</a> if
            there is a particular type of information about your subjects that you would like to store in our system.</small>
          </ItemGrid> */}

        </GridContainer>
      </form>
    );
  }
}
  
export default withStyles(style)(LayoutEditPatientBasicData);