import requireAuth from '../hocs/require_auth.jsx';
import requireNoAuth from '../hocs/no_require_auth.jsx';

import PricingPage from "views/Pages/PricingPage.jsx";
import LoginPage from "views/Pages/LoginPage.jsx";
import RegisterPage from "views/Pages/RegisterPage.jsx";
import LockScreenPage from "views/Pages/LockScreenPage.jsx";

// @material-ui/icons
import PersonAdd from "@material-ui/icons/PersonAdd";
import Fingerprint from "@material-ui/icons/Fingerprint";
import MonetizationOn from "@material-ui/icons/MonetizationOn";
import LockOpen from "@material-ui/icons/LockOpen";

const pagesRoutes = [
  // {
  //   path: "/pages/register-page",
  //   name: "Register Page",
  //   short: "Register",
  //   mini: "RP",
  //   icon: PersonAdd,
  //   component: RegisterPage
  // },
  {
    path: "/pages/sign_in",
    name: "Sign-in Page",
    short: "Sign-in",
    mini: "SP",
    icon: Fingerprint,
    component: requireNoAuth(LoginPage)
  },
  // {
  //   path: "/pages/pricing",
  //   name: "Pricing Page",
  //   short: "Pricing",
  //   mini: "PP",
  //   icon: MonetizationOn,
  //   component: PricingPage
  // },
  // {
  //   path: "/pages/lock-screen-page",
  //   name: "Lock Screen Page",
  //   short: "Lock",
  //   mini: "LSP",
  //   icon: LockOpen,
  //   component: LockScreenPage
  // },
  {
    redirect: true,
    path: "/pages",
    pathTo: "/pages/sign_in",
    name: "Register Page"
  }
];

export default pagesRoutes;
