import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Manager, Target, Popper } from "react-popper";
import { Events } from "react-scroll";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import MenuItem from "material-ui/Menu/MenuItem";
import MenuList from "material-ui/Menu/MenuList";
import ClickAwayListener from "material-ui/utils/ClickAwayListener";
import Paper from "material-ui/Paper";
import Grow from "material-ui/transitions/Grow";
import Divider from "material-ui/Divider";

import customDropdownStyle from "assets/jss/material-dashboard-pro-react/components/customDropdownStyle";
import ButtonStyled from "./ButtonStyled";

class DropdownStyled extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);    
  }

  componentDidMount() {
    Events.scrollEvent.register("begin", function() {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function() {
      console.log("end", arguments);
    });
  }
  
  componentWillUnmount() {
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
  }

  handleClick() {
    this.setState({ open: true });
  }
  handleClose() {
    this.setState({ open: false });
  }
  handleListItemClick() {
    this.handleClose();
    this.props.onListItemClick(...arguments);
  }
  render() {
    const { open } = this.state;
    const {
      classes,
      buttonColor,
      buttonText,
      buttonIcon,
      dropdownList,
      buttonProps,
      dropup,
      dropdownHeader,
      rtlActive
    } = this.props;
    const caretClasses =
      classes.caret +
      " " +
      classNames({
        [classes.caretActive]: open,
        [classes.caretRTL]: rtlActive
      });
    const dropdownItem =
      classes.dropdownItem +
      " " +
      classNames({
        [classes.dropdownItemRTL]: rtlActive
      });
    return (
      <Manager style={{ display: "inline-block" }}>
        <Target>
          <ButtonStyled 
            {...buttonProps}
            onClick={this.handleClick}
            aria-label="Notifications"
            aria-owns={open ? "menu-list" : null}
            aria-haspopup="true"
          />
        </Target>
        <Popper
          placement={dropup ? "top-start" : "bottom-start"}
          eventsEnabled={open}
          className={
            classNames({ [classes.popperClose]: !open }) +
            " " +
            classes.pooperResponsive
          }
          style={{ zIndex: 5 }} // As events of React-big-calendar have z-index = 4
        >
          <ClickAwayListener onClickAway={this.handleClose}>
            <Grow
              in={open}
              id="menu-list"
              style={
                dropup
                  ? { transformOrigin: "0 100% 0" }
                  : { transformOrigin: "0 0 0" }
              }
            >
              <Paper className={classes.dropdown}>
                <div                
                  id="scroll-container"
                  style={{
                    maxHeight: "450px",
                    overflow: "scroll"                  
                  }}
                >
                  <MenuList role="menu" className={classes.menuList}>
                    {dropdownList.map((prop, index) => {
                      if (prop.section) {
                        const returnList = []
                        if (prop.divider) {
                          returnList.push(
                            <Divider key={`divider-${index}`}
                              className={classes.dropdownDividerItem}
                            />
                          )
                        }
                        returnList.push(                       
                          <MenuItem key={`section-${index}`}
                            className={classes.dropdownHeader}
                          >
                            {prop.section}
                          </MenuItem>
                        )
                        return (
                          <div key={index}>
                            {returnList}
                          </div>
                        );
                      }
                      return (                      
                        <MenuItem
                          key={`item-${prop.id}`}
                          onClick={() => this.handleListItemClick(prop.id)}
                          className={dropdownItem}
                        >
                          {prop.code ? [<small key="1" style={{textAlign: "right"}}>[{prop.code}]</small>, <br key="2"/>] : ""}
                          <small key="3" >{prop.type}</small><br key="4"/>
                          {prop.mac}
                        </MenuItem>                      
                      );
                    })}                  
                  </MenuList>                
                </div>
              </Paper>
            </Grow>
          </ClickAwayListener>
        </Popper>
      </Manager>
    );
  }
}

DropdownStyled.propTypes = {
  classes: PropTypes.object.isRequired,
  buttonColor: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
    "defaultNoBackground",
    "primaryNoBackground",
    "infoNoBackground",
    "successNoBackground",
    "warningNoBackground",
    "dangerNoBackground",
    "roseNoBackground",
    "white",
    "simple",
    "transparent"
  ]),
  buttonText: PropTypes.node,
  buttonIcon: PropTypes.func,
  dropdownList: PropTypes.array,
  buttonProps: PropTypes.object,
  dropup: PropTypes.bool,
  dropdownHeader: PropTypes.node,
  rtlActive: PropTypes.bool,
  onListItemClick: PropTypes.func
};

export default withStyles(customDropdownStyle)(DropdownStyled);
