import _ from "lodash";
import { _REQUESTED, _SUCCESS, _ERROR, _CLEAR, UNAUTHENTICATED } from "../actions";

import Log from '../logging/Log.jsx';

function keysToCamelCase(object) {
  let camelCaseObject = _.cloneDeep(object);

  if (_.isArray(camelCaseObject)) {
    return _.map(camelCaseObject, keysToCamelCase);

  } else if(_.isPlainObject(camelCaseObject)) {
    camelCaseObject = _.mapKeys(camelCaseObject, (value, key) => {
      return _.camelCase(key);
    });

    // Recursively apply throughout object
    return _.mapValues(camelCaseObject, (value) => {
      if (_.isPlainObject(value)) {
        return keysToCamelCase(value);
      } else if (_.isArray(value)) {
        return _.map(value, keysToCamelCase);
      } else {
        return value;
      }
    });
  } else {
    return camelCaseObject;
  }
}

export default function(state, action, fetchActionType, postActionType, envelopeKey, otherActionTypes = [], initialState) {
  Log.info("In", envelopeKey, "reducer. Action type:", action.type, "and action status:", action.status)

  if (action.type === fetchActionType || action.type === postActionType || otherActionTypes.indexOf(action.type) !== -1) {
    var newState;

    if (action.status === _REQUESTED) {
        newState = { ...state, isRequesting: true, error: null, posted: null }
    }
    else {
      const isRequesting = false;

      if (action.status === _CLEAR && state.byId) {
        newState = { 
          byId: {}, 
          isRequesting, 
          posted: null,
          error: null
        };
      }

      else if (action.status === _SUCCESS) {
        newState = { 
          byId: state.byId, 
          isRequesting, 
          posted: action.type === postActionType ? action.payload[envelopeKey][0].id : null,
          error: null
        };

        action.payload[envelopeKey].forEach(
          (received) => {
            let data = keysToCamelCase(received);
            Log.info("Converted", received, "to", data)
            newState = _.set(newState, 'byId.'+data.id, data);
          }
        )
      }

      else /* if (action.status === _ERROR) */ {
        Log.info("Upon action of type", action.type, "reducer received an error:", action.payload)
        newState = { 
          byId: state.byId, 
          isRequesting,
          posted: (action.type === postActionType /*&& action.payload.status ===  HttpStatus.UNPROCESSABLE_ENTITY*/) ? false : null,
          error: action.payload // removed ".data" as error.data used in actions
        };
      }
    }
    Log.info("Finishing", envelopeKey, "reducer")
    return newState;
  }
  else if (action.type === UNAUTHENTICATED) {
    Log.info("Finishing", envelopeKey, "reducer")
    return Object.assign({}, initialState);
    // return state;
  }
  else {
    Log.info("Finishing", envelopeKey, "reducer")
    return state;
  }
}