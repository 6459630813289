import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import Card from "material-ui/Card";
import CardContent from "material-ui/Card/CardContent";
import CardHeader from "material-ui/Card/CardHeader";
import CardActions from "material-ui/Card/CardActions";

import MainTableHeaderLinks from "components/Cards/MainTableHeaderLinks";

import headerCardStyle from "assets/jss/material-dashboard-pro-react/components/headerCardStyle.jsx";

function HeaderCard({ ...props }) { 

  const {
    classes,
    headerColor,
    plainCard,
    cardTitle,
    cardSubtitle,
    content,
    footer,
    footerAlign,
    search
  } = props;
  const plainCardClasses = cx({
    [" " + classes.cardPlain]: plainCard
  });
  const cardPlainHeaderClasses = cx({
    [" " + classes.cardPlainHeader]: plainCard
  });
  const cardFooterClasses =
    classes.cardActions +
    "  " +
    cx({
      [classes[footerAlign]]: footerAlign !== undefined
    });

  return (
    <Card className={classes.card + plainCardClasses}>
      <CardHeader
        classes={{
          root:
            classes.cardHeader +
            " " +
            classes[headerColor + "CardHeader"] +
            cardPlainHeaderClasses,
          // avatar: classes.cardAvatar,
          title: classes.cardTitle,
          subheader: classes.cardSubtitle
        }}
        title={cardTitle}
        subheader={cardSubtitle}
        // avatar={<props.icon className={classes.cardIcon} />}
        // action={
        //   <IconButton>
        //     <Close/>
        //   </IconButton>
        // }
      />
      
      {search && <MainTableHeaderLinks/>}
      
      <CardContent className={classes.cardContent}>{content}</CardContent>
      {footer !== undefined ? (
        <CardActions className={cardFooterClasses}>{footer}</CardActions>
      ) : null}
    </Card>
  );
}

HeaderCard.defaultProps = {
  headerColor: "purple"
};

HeaderCard.propTypes = {
  plainCard: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  headerColor: PropTypes.oneOf([
    "orange",
    "green",
    "red",
    "blue",
    "purple",
    "rose",
    "NEStyle"
  ]),
  cardTitle: PropTypes.node,
  cardSubtitle: PropTypes.node,
  content: PropTypes.node,
  footer: PropTypes.node,
  footerAlign: PropTypes.oneOf(["left", "center", "right"])
};

export default withStyles(headerCardStyle)(HeaderCard);
