import requireAuth from '../hocs/require_auth.jsx';
import notifiable from '../hocs/notifiable';

import TreatmentsMain from '../ecosystems/TreatmentsMain.jsx';
import PatientMain from '../ecosystems/PatientMain.jsx';
import HistoryMain from '../ecosystems/HistoryMain.jsx';
import UserProfile from 'views/Pages/UserProfile.jsx';
import TimelinePage from 'views/Pages/Timeline.jsx';
import RTLSupport from 'views/Pages/RTLSupport.jsx';

import pagesRoutes from './pages.jsx';

// @material-ui/icons
import Home from '@material-ui/icons/Home';
import Person from '@material-ui/icons/Person';

var pages = [
  {
    path: '/timeline-page',
    name: 'Timeline Page',
    mini: 'TP',
    component: TimelinePage
  },
  {
    path: '/user-page',
    name: 'User Profile',
    mini: 'UP',
    component: UserProfile
  },
  {
    path: '/rtl/rtl-support-page',
    name: 'RTL Support',
    mini: 'RS',
    component: RTLSupport
  }
].concat(pagesRoutes);

var dashRoutes = [
  {
    parameterized: true,
    path: '/studies/subjects/:code/history',
    name: 'Home Study Manager > Subject History',
    icon: Person,
    component: requireAuth(notifiable(HistoryMain))
  },
  {
    parameterized: true,
    path: '/studies/subjects/:code',
    name: 'Home Study Manager > Subject Profile',
    icon: Person,
    component: requireAuth(notifiable(PatientMain))
  },
  {
    path: '/studies',
    name: 'Home Study Manager',
    icon: Home, // ContentPaste,
    component: requireAuth(notifiable(TreatmentsMain))
  },
  {
    path: '/fckytcdo7df867dy',
    hr: true
  },
  { redirect: true, path: '/', pathTo: '/studies', name: 'Home Study Manager' }
];
export default dashRoutes;
