import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Log from '../logging/Log.jsx';

// core components

import { patientByCodeSelector } from "../selectors/patientSelectors";
import { assignDeviceToPatient, unAssignDeviceFromPatient, getDevices, pushNotification } from '../actions/index';
import { unassignedDevicesSelector, patientDeviceSelector, assignedDevicesSelector } from "../selectors/deviceSelectors";
import DropdownStyled from "../molecules/DropdownStyled";
import ModalConfirmation from "../molecules/ModalConfirmation";

import {DataSubmissionStates, DataDeleteStates} from '../reducers/utils.js'


class PatientDeviceAssignmentMenu extends React.Component {

  constructor(props) {
    Log.info("Creating TreatmentSchedulerSimple with props:", props);
    super(props);
    this.state = {
      assignConfirmationRequired: false,
      assignConfirmationDevice: null,
      unAssignConfirmationRequired: false,
      unAssignConfirmationDevice: null
    };

    this.deviceChosenForAssignment = this.deviceChosenForAssignment.bind(this);
    this.deviceConfirmedForAssignment = this.deviceConfirmedForAssignment.bind(this);
    this.deviceAssignmentCancelled = this.deviceAssignmentCancelled.bind(this);
    this.UnAssignedDeviceComponent = this.UnAssignedDeviceComponent.bind(this);
    this.AssignedDeviceComponent = this.AssignedDeviceComponent.bind(this);
    this.assignDevice = this.assignDevice.bind(this);
    this.deviceChosenForUnAssignment = this.deviceChosenForUnAssignment.bind(this);
    this.deviceConfirmedForUnAssignment = this.deviceConfirmedForUnAssignment.bind(this);
    this.deviceUnAssignmentCancelled = this.deviceUnAssignmentCancelled.bind(this);
    this.unAssignDevice = this.unAssignDevice.bind(this);
  }

  componentDidMount() {
    if(this.props.deviceAssigned === false) {
      this.props.getDevices();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.deviceAssigned !== this.props.deviceAssigned) {    
      this.props.getDevices();
    }

    if (prevProps.assignmentSubmissionState === DataSubmissionStates.POSTING) {
      if (this.props.assignmentSubmissionState === DataSubmissionStates.POSTED) {
        //this.closeWizard();
      }
      else if (this.props.assignmentSubmissionState === DataSubmissionStates.FAILED) {
        this.props.pushNotification("Failed to assign! " + this.props.errorMessage);
      }
    }

    if (prevProps.unUssignmentSubmissionState === DataDeleteStates.DELETING) {
      if (this.props.unAssignmentSubmissionState === DataDeleteStates.DELETED) {
        //this.closeWizard();
      }
      else if (this.props.unAssignmentSubmissionState === DataDeleteStates.FAILED) {
        this.props.pushNotification("Failed to unassign! " + this.props.errorMessage);
      }
    }
  }

  deviceChosenForAssignment(id) {
    const {allAssignedDevices, allUnassignedDevices} = this.props;

    let chosenAssignedDevices = allAssignedDevices.filter(device => device.id === id);
    if (chosenAssignedDevices.length > 0) {
      Log.info("Chosen assigned device:", id);
      this.setState({assignConfirmationRequired: true, assignConfirmationDevice: chosenAssignedDevices[0]});
    }
    else {
      let chosenUnassignedDevices = allUnassignedDevices.filter(device => device.id === id);
      if (chosenUnassignedDevices.length > 0) {
        this.assignDevice(id);
      }
    }
  }

  deviceConfirmedForAssignment() {
    const id = this.state.assignConfirmationDevice.id;
    this.setState({assignConfirmationRequired: false, assignConfirmationDevice: null});
    this.assignDevice(id);
  }

  deviceAssignmentCancelled() {
    this.setState({assignConfirmationRequired: false, assignConfirmationDevice: null})
  }

  assignDevice(deviceId) {
    Log.info("Will assign the device:", deviceId, "to patient", this.props.patientId)
    this.props.assignDeviceToPatient(this.props.patientId, deviceId)
  }

  unAssignDevice() {
    Log.info("Will unassign the device:", this.props.patientDevice, "from patient", this.props.patientId)
    this.props.unAssignDeviceFromPatient(this.props.patientId, this.props.patientDevice)
  }

  deviceChosenForUnAssignment() {
    this.setState({unAssignConfirmationRequired: true, unAssignConfirmationDevice: this.props.patientDevice})
  }

  deviceConfirmedForUnAssignment() {
    this.setState({unAssignConfirmationRequired: false, unAssignConfirmationDevice: null});
    this.unAssignDevice();
  }

  deviceUnAssignmentCancelled() {
    this.setState({unAssignConfirmationRequired: false, unAssignConfirmationDevice: null});
  }

  AssignedDeviceComponent(props) {
    let dropdownList = []
    dropdownList.push(props.patientDevice)
    dropdownList.push({ section: "Click on the device name to finalize assignment" })

    return (
      <DropdownStyled
        buttonProps={{
          icon: "NEDevice",
          message: "Device assigned",
          inactive: true,
          dropdown : true,
          fullWidth: true
        }}
        dropdownList={dropdownList}
        onListItemClick={() => this.deviceChosenForUnAssignment()}
      />
    )
  }

  UnAssignedDeviceComponent(props) {
    const {freeDevices, assignedDevices} = props;

    let dropdownList = []
    dropdownList.push({ section: "Available devices" })
    dropdownList.push(...freeDevices)
    dropdownList.push({ section: "Assigned devices", divider: true })
    dropdownList.push(...assignedDevices)

    return (
      <DropdownStyled
        buttonProps={{
          icon: "NEDevice",
          message: "Assign device",
          inactive: false,
          dropdown : true,
          fullWidth: true
        }}
        dropdownList={dropdownList}
        onListItemClick={(id) => this.deviceChosenForAssignment(id)}
      />
    )
  }

  AssignedPatientChangeConfirmationModal(props) {
    const {deviceMac, currentPatientCode, newPatientCode, onConfirm, onCancel} = props;

    return (
      <ModalConfirmation
        content={
          <div>
            <p>The device {deviceMac} is currently assigned to {currentPatientCode}.</p>
            <p>If you assign this device to {newPatientCode}, the assignment to {currentPatientCode} will be finalized.</p>
            <p>Are you sure you want continue with the assignment?</p>
          </div>
          }
        cancelText="Cancel"
        confirmationText="Confirm"
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    )
  }

  UnAssignPatientDeviceConfirmationModal(props) {
    const {deviceMac, currentPatientCode, onConfirm, onCancel} = props;

    return (
      <ModalConfirmation
        content={
          <div>
            <p style={{color: "red"}}>WARNING! You are about to unassign the device {deviceMac} from {currentPatientCode}.</p>            
            <p>Are you sure you want continue with the unassignment?</p>
          </div>
          }
        cancelText="Cancel"
        confirmationText="Confirm"
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    )
  }

  render() {
    const { deviceAssigned, patientDevice, allAssignedDevices, allUnassignedDevices, patientCode } = this.props;
    const { assignConfirmationRequired, assignConfirmationDevice, unAssignConfirmationRequired, unAssignConfirmationDevice } = this.state;

    let componentsToRender = [];

    if(deviceAssigned) {
      componentsToRender.push(
        <this.AssignedDeviceComponent
          key={patientDevice.id}
          patientDevice={patientDevice}
        />)
    } else {
      componentsToRender.push(
        <this.UnAssignedDeviceComponent
          key={0}
          assignedDevices={allAssignedDevices} 
          freeDevices={allUnassignedDevices}
        /> 
      )
    }

    if(assignConfirmationRequired) {
      componentsToRender.push(
        <this.AssignedPatientChangeConfirmationModal
          key={assignConfirmationDevice.id}
          deviceMac={assignConfirmationDevice.mac}
          currentPatientCode={assignConfirmationDevice.code}
          newPatientCode={patientCode}
          onCancel={this.deviceAssignmentCancelled}
          onConfirm={this.deviceConfirmedForAssignment}
        />
      )
    }

    if(unAssignConfirmationRequired) {
      componentsToRender.push(
        <this.UnAssignPatientDeviceConfirmationModal
          key={1}
          deviceMac={unAssignConfirmationDevice.mac}
          currentPatientCode={patientCode}         
          onCancel={this.deviceUnAssignmentCancelled}
          onConfirm={this.deviceConfirmedForUnAssignment}
        />
      )
    }

    return (
      <div>
        {componentsToRender.map(component => component)}
      </div>
    )
  }
}

function mapStateToProps(state, props) {
  Log.info("PatientDeviceAssignmentMenu - mapStateToProps", state)
  let patient = patientByCodeSelector(state, props.patientCode)
  let patientDevice = patientDeviceSelector(patient, state)
  let patientId
  if (patient) { patientId = patient.id }  
  let allAssignedDevices, allUnassignedDevices
  let deviceAssigned = true
  if (patientDevice === null) {
    allAssignedDevices = assignedDevicesSelector(state)
    allUnassignedDevices = unassignedDevicesSelector(state)
    deviceAssigned = false
  }

  let assignmentSubmissionState
  let errorMessage = null
  if ( !state.patients.isFetching && !state.patients.posted && state.patients.error === null ) assignmentSubmissionState = DataSubmissionStates.IDLE;
  else if ( state.patients.isFetching && !state.patients.posted ) assignmentSubmissionState = DataSubmissionStates.POSTING;
  else if ( !state.patients.isFetching && state.patients.posted ) assignmentSubmissionState = DataSubmissionStates.POSTED;
  else if ( !state.patients.isFetching && state.patients.error !== null ) {
    assignmentSubmissionState = DataSubmissionStates.FAILED;
    errorMessage = state.patients.error.data.message;
  }

  Log.info("Assignment submission state:", assignmentSubmissionState)

  Log.info("Patient", patient, "PatientDevice", patientDevice, "assigned", allAssignedDevices, "unassigned", allUnassignedDevices, "deviceAssigned?", deviceAssigned)
  
  let unAssignmentSubmissionState  
  if ( !state.patients.isFetching && !state.patients.deleted && state.patients.error === null ) unAssignmentSubmissionState = DataDeleteStates.IDLE;
  else if ( state.patients.isFetching && !state.patients.deleted ) unAssignmentSubmissionState = DataDeleteStates.DELETING;
  else if ( !state.patients.isFetching && state.patients.deleted ) unAssignmentSubmissionState = DataDeleteStates.DELETED;
  else if ( !state.patients.isFetching && state.patients.error !== null ) {
    unAssignmentSubmissionState = DataDeleteStates.FAILED;
    errorMessage = state.patients.error.data.message;
  }

  Log.info("Unassignment submission state:", unAssignmentSubmissionState)

  return { patientId, deviceAssigned, patientDevice, 
    allAssignedDevices, allUnassignedDevices,
    assignmentSubmissionState, unAssignmentSubmissionState, errorMessage }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({assignDeviceToPatient, unAssignDeviceFromPatient, getDevices, pushNotification}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientDeviceAssignmentMenu);