import React, { Component } from "react"
import { connect } from 'react-redux'
import { bindActionCreators } from "redux"
import compose from "recompose/compose"
import { getEegLink, removeEegLink, getCsvLink, removeCsvLink } from "../actions/index"

import Chip from "material-ui/Chip"
import Avatar from "material-ui/Avatar"

import { withStyles } from "material-ui/styles"

import moment from "moment"

import Warning from "@material-ui/icons/Warning"
import Error from "@material-ui/icons/Error"
import FileDownload from "@material-ui/icons/FileDownload"
import TimeLapse from "@material-ui/icons/Timelapse"

const styles = theme => ({
  root: {},
  chip: {
    height: 16,
    backgroundColor: "white",
    fontSize: 10,
    borderRadius: 8
  },
  avatar: {
    height: 16,
    width: 16,
    borderRadius: 8,
    backgroundColor: "white"
  },
  iconAvatar: {
    height: 16,
    width: 16,
    borderRadius: 8,
    backgroundColor: "red"
  },
  iconChip: {
    height: 0,
    width: "auto",
    fontSize: 10,
    borderRadius: 8
  },
  error: {
    color: "white"
  },
  card: {
    // height: "60px",
    // marginBottom: "30px"
  }
})

const cstyles = {
  iconAvatar: {
    background: "darkorange",
    color: "white"
  },
  iconAvatarSessionRecord: {
    background: "#2D9434",
    color: "white"
  },
  iconChip: {
    marginRight: ".5%",
    cursor: "pointer",
    background: "orange",
    color: "white",
    fontWeight: "400",
    boxShadow:
      "0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12)"
  },
  iconChipSessionRecord: {
    marginRight: ".5%",
    cursor: "pointer",
    background: "#4caf50",
    color: "white",
    fontWeight: "400",
    boxShadow:
      "0 2px 2px 0 rgba(156, 39, 176, 0.14), 0 3px 1px -2px rgba(156, 39, 176, 0.2), 0 1px 5px 0 rgba(156, 39, 176, 0.12)"
  }
}

class CardAgendaCalendarSession extends Component { 
  
  render() {
    const timestring = this.props.event.allDay
      ? "All day"
      : moment(this.props.event.start).format("hA") +
        " - " +
        moment(this.props.event.end).format("hA")

    const { classes } = this.props

    const chipDuration = this.props.event.duration ? (
      <Chip
        avatar={
          <Avatar className={classes.avatar}>
            <TimeLapse />
          </Avatar>
        }
        label={
          this.props.event.executed +
          "/" +
          this.props.event.duration +
          "minutes"
        }
        className={classes.chip}
      />
    ) : (
      ""
    )

    const chipError = this.props.event.problem ? (
      <Chip
        avatar={
          <Avatar className={classes.iconAvatar}>
            <Error className={classes.error} />
          </Avatar>
        }
        className={classes.iconChip}
      />
    ) : (
      ""
    )

    const download =
      this.props.event.eeg.length > 0 &&
      this.props.event.eeg.map((item, index) => {
        return (
          item.step && (
            <Chip              
              onClick={() => handleClick(item.id, item.step)}
              avatar={
                <Avatar style={cstyles.iconAvatar}>
                  <FileDownload />
                </Avatar>
              }
              label={item.step}
              style={cstyles.iconChip}
              key={index}
            />
          )
        )
      })

      const handleClick = async (id, step) => {
        try {      
            await this.props.getEegLink(id)
            const link = this.props.eeg.link
                
            const a = document.createElement("a")
            a.setAttribute("href", link)
            a.setAttribute("download", step)
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a) 
    
            this.props.removeEegLink()     
    
        } catch(error) {
          console.log(error)
        }    
      }

      let sessionEvents
      if (this.props.event.incidents === "Missed") {
        sessionEvents = false
      } else {
        sessionEvents = true
      }

      const downloadCsv =           
        <Chip              
          onClick={() => handleClickCsv(this.props.event.id)}
          avatar={
            <Avatar style={cstyles.iconAvatarSessionRecord}>
              <FileDownload />
            </Avatar>
          }
          label="Session Record File (.csv)"
          style={cstyles.iconChipSessionRecord}              
        />
          
          

      const handleClickCsv = async (id) => {
        try {          
          await this.props.getCsvLink(id)                
          const a = document.createElement("a")
          const file = new Blob([this.props.csv.content], {type: 'text/plain'});
          a.href = URL.createObjectURL(file);
          a.download = this.props.csv.filename;          
          document.body.appendChild(a)
          a.click()
          document.body.removeChild(a)    
          this.props.removeCsvLink()     
    
        } catch(error) {
          console.log(error)
        }    
      }

    return (
      <div className={classes.card}>
        {this.props.event.title}
        <br />
        <strong>{timestring}</strong>
        <br />
        {chipError} {chipDuration}
        <br />
        {sessionEvents && (
          <div style={{ display: "flex" }}>            
            {downloadCsv} 
              {this.props.event.eeg.length > 0 && (
                <div style={{ display: "flex" }}>                  
                  {download}                  
                </div>
              )}                
          </div>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {  
  return { 
    eeg: state.eeg,
    csv: state.csv,    
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getEegLink, removeEegLink, getCsvLink, removeCsvLink },
    dispatch
  )
}

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(CardAgendaCalendarSession)


