import React, { Fragment } from "react";

import Log from "../logging/Log.jsx";

// material-ui components
import withStyles from "material-ui/styles/withStyles";

import TimeLapse from "@material-ui/icons/Timelapse";
import TrendingUp from "@material-ui/icons/TrendingUp";
import TrendingDown from "@material-ui/icons/TrendingDown";

import Select from "material-ui/Select";
import MenuItem from "material-ui/Menu/MenuItem";
import InputLabel from "material-ui/Input/InputLabel";
import FormControl from "material-ui/Form/FormControl";

// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx";

import { SponstimIcon, NGPistimIcon } from "../icons/NEIcons.jsx";

import moment from "moment";

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  iconText: {
    verticalAlign: "middle",
    padding: "10px",
  },
  text: {
    verticalAlign: "middle",
    "& span": {
      fontStyle: "italic",
    },
  },
  electrodeText: {
    verticalAlign: "middle",
    paddingLeft: "10px",
  },
  iconTextSelect: {
    height: "20px",
    alignItems: "center",
    display: "flex",
    marginBottom: "0px",
  },
  iconTextParent: {
    height: "20px",
    alignItems: "center",
    display: "flex",
    marginBottom: "10px",
  },
  table: {
    marginBottom: "10px",
  },
  icons2: {
    width: "24px",
  },
  ...customSelectStyle,
};

const electrodeIconComponents = {
  Sponstim: SponstimIcon,
  "NG Pistim": NGPistimIcon,
};

class DeviceProtocolRepresentation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      simpleSelect: "",
      desgin: false,
      code: false,
      develop: false,
      electrodeModel: "",
    };

    this.renderElectrodeElement = this.renderElectrodeElement.bind(this);
  }

  handleSimple = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  isValidated() {
    return true;
  }

  getMontage(i) {
    return this.props.steps[i].personalizedMontage
      ? this.props.steps[i].personalizedMontage
      : this.props.steps[i].defaultMontage;
  }

  renderElectrodeElement(electrodeModel, classes) {
    if (electrodeModel) {
      const Icon = electrodeIconComponents[electrodeModel];

      return (
        <p className={classes.iconTextParent}>
          <Icon className={classes.icons2} />
          <span className={classes.electrodeText}>
            {electrodeModel} electrode
          </span>
        </p>
      );
    } else {
      return (
        <FormControl
          fullWidth
          className={classes.selectFormControl}
          error={this.props.showElectrodeError}
          required
        >
          <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
            Choose electrode model...
          </InputLabel>
          <Select
            MenuProps={{
              className: classes.selectMenu,
            }}
            classes={{
              select: classes.select,
            }}
            value={this.state.electrodeModel}
            inputProps={{
              name: "lang",
              id: "simple-select",
              onChange: (event) => {
                this.setState({ electrodeModel: event.target.value });
                this.props.onElectrodeModelChange(event.target.value);
              },
            }}
          >
            <MenuItem
              disabled
              classes={{
                root: classes.selectMenuItem,
              }}
              key={0}
            >
              Choose electrode model...
            </MenuItem>
            <MenuItem
              key={1}
              value={"NG Pistim"}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
            >
              <p className={classes.iconTextSelect}>
                <NGPistimIcon className={classes.icons2} />
                <span className={classes.electrodeText}>
                  NG Pistim electrode
                </span>
              </p>
            </MenuItem>
            <MenuItem
              key={2}
              value={"Sponstim"}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
            >
              <p className={classes.iconTextSelect}>
                <SponstimIcon className={classes.icons2} />
                <span className={classes.electrodeText}>
                  Sponstim electrode
                </span>
              </p>
            </MenuItem>
          </Select>
        </FormControl>
      );
    }
  }

  render() {
    const { classes } = this.props;

    Log.info("Generating representation of the device protocol:", this.props);

    let multiStep;
    this.props.steps.length > 1 ? (multiStep = true) : (multiStep = false);

    return (
      <GridContainer
        style={{
          maxHeight: "65vh",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <ItemGrid xs={12} sm={12}>
          <h4>Starstim Protocol</h4>
        </ItemGrid>
        {multiStep && (
          <Fragment>
            <ItemGrid xs={12} sm={6}>
              {this.props.steps[0].realStimulation === undefined ||
              this.props.steps[0].realStimulation === null ? (
                <span> </span>
              ) : (
                <h5>{this.props.name}</h5>
              )}
            </ItemGrid>

            <ItemGrid xs={12} sm={6}>
              {this.renderElectrodeElement(this.props.stimElectrode, classes)}
            </ItemGrid>
          </Fragment>
        )}

        {this.props.steps.map((step, i) => (
          <GridContainer
            key={i}
            style={{
              paddingTop: "2%",
              paddingBottom: "2%",
            }}
          >
            <ItemGrid xs={1} sm={1} />

            <ItemGrid xs={11} sm={11}>
              <GridContainer>
                <ItemGrid xs={12} sm={8}>
                  <p className={classes.iconTextParent}>
                    <span className={classes.text}>
                      {multiStep
                        ? step.name
                        : step.realStimulation === undefined ||
                          step.realStimulation === null
                        ? ""
                        : this.props.name}
                    </span>
                  </p>
                </ItemGrid>

                <ItemGrid xs={12} sm={4}>
                  {!step.isOnlyEeg && (
                    <p className={classes.iconTextParent}>
                      <span className={classes.text}>
                        {step.realStimulation ? (
                          "Real stimulation"
                        ) : step.realStimulation === undefined ||
                          step.realStimulation === null ? (
                          <span>Blinded</span>
                        ) : (
                          "Sham stimulation"
                        )}
                      </span>
                    </p>
                  )}
                </ItemGrid>

                <ItemGrid xs={12} sm={4}>
                  <p className={classes.iconTextParent}>
                    <TimeLapse />
                    <span className={classes.iconText}>
                      {step.duration < 60
                        ? step.duration + "sec"
                        : Math.ceil(step.duration / 60) + "min"}
                    </span>
                  </p>
                </ItemGrid>

                {!step.isOnlyEeg && (
                  <ItemGrid xs={12} sm={4}>
                    <p className={classes.iconTextParent}>
                      <TrendingUp />
                      <span className={classes.iconText}>
                        {moment.unix(step.rampUpDuration).format("m:ss")}
                      </span>
                    </p>
                  </ItemGrid>
                )}

                {!step.isOnlyEeg && (
                  <ItemGrid xs={12} sm={4}>
                    <p className={classes.iconTextParent}>
                      <TrendingDown />
                      <span className={classes.iconText}>
                        {moment.unix(step.rampDownDuration).format("m:ss")}
                      </span>
                    </p>
                  </ItemGrid>
                )}

                <ItemGrid xs={12} sm={12}>
                  <GridContainer className={classes.table}>
                    <ItemGrid xs={12} sm={4}>
                      Location
                    </ItemGrid>
                    <ItemGrid xs={12} sm={4}>
                      Function
                    </ItemGrid>
                    <ItemGrid xs={12} sm={4}>
                      Cable
                    </ItemGrid>
                    {this.getMontage(i).map((montage) => {
                      if (!montage.location.match("Chd*"))
                        return [
                          <ItemGrid key={0} xs={12} sm={4}>
                            {montage.location}
                          </ItemGrid>,
                          <ItemGrid
                            key={1}
                            xs={12}
                            sm={4}
                            className={classes.text}
                          >
                            {step.realStimulation === undefined ||
                            step.realStimulation === null ? (
                              <span>blinded</span>
                            ) : (
                              montage.function
                            )}
                          </ItemGrid>,
                          <ItemGrid key={2} xs={12} sm={4}>
                            {montage.cable}
                          </ItemGrid>,
                        ];
                    })}
                  </GridContainer>
                </ItemGrid>
              </GridContainer>
            </ItemGrid>
          </GridContainer>
        ))}
        {!multiStep && (
          <ItemGrid xs={12} sm={6}>
            {this.renderElectrodeElement(this.props.stimElectrode, classes)}
          </ItemGrid>
        )}
      </GridContainer>
    );
  }
}

export default withStyles(style)(DeviceProtocolRepresentation);
