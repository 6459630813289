import React, { Component } from 'react';

class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { error: null, errorInfo: null };
    }
    
    componentDidCatch(error, errorInfo) {      
      this.setState({
        error: error,
        errorInfo: errorInfo,
      })      
    }

    refreshPage = () => window.location.reload();
    
    render() {
      if (this.state.errorInfo) {        
        return (
          <div style={{ padding: '50px' }}>
            <h2>Something went wrong.</h2>
            <details style={{ whiteSpace: 'pre-wrap' }}>
              {this.state.error && this.state.error.toString()}
              <br />
              {this.state.errorInfo.componentStack}
            </details>
            <br/>
            <button onClick={this.refreshPage}>
                Refresh Page
            </button>
          </div>
        );
      }
      
      return this.props.children;
    }  
  }

  export default ErrorBoundary;
