import { FETCH_CSV_LINK, REMOVE_CSV_LINK, _SUCCESS, _REQUESTED, _ERROR } from "../actions";

import Log from "../logging/Log.jsx";

const initialState = {  
  content: null,
  filename: null,
  isRequesting: false,
  error: null,
  posted: false
};

export default function(state = initialState, action) {
  Log.info("Received action:", action.type);
  Log.info("Current state:", state);

  switch (action.type) {
    case FETCH_CSV_LINK:
      if (action.status === _REQUESTED) {
        return { ...state, isRequesting: true };
      } else {
        if (action.status === _SUCCESS) {
          return {
            ...state,            
            content: action.payload.content,
            filename: action.payload.filename,
            isRequesting: false
          };
        } /* if (action.status === _ERROR) */ else {
          Log.info("Upon action of type", action.type, "reducer received an error:", action.payload);
          return { ...state, error: action.payload };
        }
      };
    case REMOVE_CSV_LINK:
      return initialState;           
    default:
      return state;
  }
}
