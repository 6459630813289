import { localDeployment } from '../actions/api.js'

const BASE = 'ne-portal-debug';
const COLOURS = {
  trace: 'lightblue',
  info: 'blue',
  warn: 'pink',
  error: 'red'
}; // choose better colours :)

class Log {
  generateMessage(level, message) {

    if (localDeployment) {
      console.log(...message);
    }

    // const namespace = `${BASE}:${level}`;
    // const createDebug = debug(namespace);

    // for(var i=0; i<message.length; i++) {
    //   if (typeof(message[i]) == 'object') {
    //     try {
    //       message[i] = JSON.stringify(message[i])
    //     } catch (err) {
    //       message[i] = "object_with_cyclic_references"
    //     }
    //   } 
    //   else message[i] = String(message[i])
    // }

    // const result = message.join(' ');
    
    // createDebug.color = COLOURS[level];
    
    // createDebug(result);
  }
  
  trace(...message) {
    return this.generateMessage('trace', message);
  }
  
  info(...message) {
    return this.generateMessage('info', message);
  }
  
  warn(...message) {
    return this.generateMessage('warn', message);
  }
  
  error(...message) {
    return this.generateMessage('error', message);
  }
}

export default new Log();