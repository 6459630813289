import React from 'react'
import PropTypes from 'prop-types'

import Log from '../logging/Log.jsx'

import CalendarSessions from '../organisms/CalendarSessions'

import GridContainer from 'components/Grid/GridContainer.jsx'
import ItemGrid from 'components/Grid/ItemGrid.jsx'

import PatientAvatar from '../molecules/PatientAvatar.jsx'
import PatientDeviceAssignmentMenu from '../organisms/PatientDeviceAssignmentMenu'
import ReactGA from 'react-ga'

export const initializeReactGA = () => {
  ReactGA.initialize('UA-100557389-4')
}

export const logPageView = () => {
  ReactGA.set({ page: window.location.pathname })
  ReactGA.pageview(window.location.pathname)
}

const PatientEcosystem = ({ match }) => {
  Log.info('Patient Main')

  Log.info('Generating patient ecosystem')

  // Google analytics -> Check production/development
  if (
    window.location.href ==
    `https://ne-portal-app.azurewebsites.net/studies/subjects/${
      match.params.code
    }`
  ) {
    initializeReactGA()
    logPageView()
  }

  return (
    <GridContainer justify='center'>
      <ItemGrid xs={12} sm={12} md={6}>
        <PatientAvatar code={match.params.code} />
      </ItemGrid>
      <ItemGrid xs={12} sm={12} md={6}>
        <div style={{ textAlign: 'right' }}>
          <PatientDeviceAssignmentMenu patientCode={match.params.code} />
        </div>
      </ItemGrid>
      <ItemGrid xs={12} sm={12} md={12}>
        <CalendarSessions patientCode={match.params.code} />
      </ItemGrid>
    </GridContainer>
  )
}

PatientEcosystem.propTypes = {
  match: PropTypes.object.isRequired
}

export default PatientEcosystem
