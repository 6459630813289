import { NOTIFY, END_NOTIFY } from "../actions";
import axios from 'axios';

import Log from '../logging/Log.jsx';

const initialState = { show: false, message: "" }

export default function(state = initialState, action) {
    switch (action.type) {
        case NOTIFY:
            Log.info("Changed axios default headers:", axios.defaults.headers);
            return { show: true, message: action.payload };
        case END_NOTIFY:
            return initialState;
        default:
            return state;
    }
}