import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// material-ui components
import withStyles from "material-ui/styles/withStyles";
import Card from "material-ui/Card";
import HeaderCard from "components/Cards/HeaderCard.jsx";


// core components
import Button from "components/CustomButtons/Button.jsx";

import wizardStyle from "assets/jss/material-dashboard-pro-react/components/wizardStyle.jsx";

class Wizard extends React.Component {
  constructor(props) {
    super(props);
    var width;
    if (this.props.steps.length === 1) {
      width = "100%";
    } else {
      if (window.innerWidth < 600) {
        if (this.props.steps.length !== 3) {
          width = "50%";
        } else {
          width = 100 / 3 + "%";
        }
      } else {
        if (this.props.steps.length === 2) {
          width = "50%";
        } else {
          width = 100 / 3 + "%";
        }
      }
    }
    this.state = {
      currentStep: 0,
      color: this.props.color,
      cancelButton: this.props.cancelButtonClick,
      nextButton: this.props.steps.length > 1 ? true : false,
      previousButton: false,
      finishButton: this.props.steps.length === 1 ? true : false,
      batchButton: false,
      width: width,
      movingTabStyle: {
        transition: "transform 0s"
      }
    };
    this.navigationStepChange = this.navigationStepChange.bind(this);
    this.refreshAnimation = this.refreshAnimation.bind(this);
    this.cancelButtonClick = this.cancelButtonClick.bind(this);
    this.previousButtonClick = this.previousButtonClick.bind(this);
    this.finishButtonClick = this.finishButtonClick.bind(this);
    this.renderWizardNavigation = this.renderWizardNavigation.bind(this);
    this.showBatchButton = this.showBatchButton.bind(this);
    this.batchButtonClick = this.batchButtonClick.bind(this);
    this.getOutput = this.getOutput.bind(this);
  }
  componentDidMount() {
    this.refreshAnimation(0);
    window.addEventListener("resize", this.updateWidth.bind(this));
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWidth.bind(this), true);
  }
  updateWidth() {
    this.refreshAnimation(this.state.currentStep);
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.batchButtonText && prevProps !== this.props) {
      this.showBatchButton()
    }
  }
  navigationStepChange(key) {
    if (this.props.steps) {
      var validationState = true;
      if (key > this.state.currentStep) {
        for (var i = this.state.currentStep; i < key; i++) {
          if (
            this[this.props.steps[i].stepId].isValidated !== undefined &&
            this[this.props.steps[i].stepId].isValidated() === false
          ) {
            validationState = false;
            break;
          }
        }
      }
      if (validationState) {
        this.setState({
          currentStep: key,
          nextButton: this.props.steps.length > key + 1 ? true : false,
          previousButton: key > 0 ? true : false,
          cancelButton: key > 0 ? false : this.props.cancelButtonClick,
          finishButton: this.props.steps.length === key + 1 ? true : false
        });
        this.refreshAnimation(key);
      }
    }
  }
  cancelButtonClick() {
    this.props.cancelButtonClick();
  }
  nextButtonClick() {
    if (
      (this.props.validate &&
        ((this[this.props.steps[this.state.currentStep].stepId].isValidated !==
          undefined &&
          this[
            this.props.steps[this.state.currentStep].stepId
          ].isValidated()) ||
          this[this.props.steps[this.state.currentStep].stepId].isValidated ===
            undefined)) ||
      this.props.validate === undefined
    ) {
      var key = this.state.currentStep + 1;
      this.setState({
        currentStep: key,
        nextButton: this.props.steps.length > key + 1 ? true : false,
        previousButton: key > 0 ? true : false,
        cancelButton: key > 0 ? false : this.props.cancelButtonClick,
        finishButton: this.props.steps.length === key + 1 ? true : false
      });
      this.refreshAnimation(key);
    }
  }
  previousButtonClick() {
    var key = this.state.currentStep - 1;
    if (key >= 0) {
      this.setState({
        currentStep: key,
        nextButton: this.props.steps.length > key + 1 ? true : false,
        previousButton: key > 0 ? true : false,
        cancelButton: key > 0 ? false : this.props.cancelButtonClick,
        finishButton: this.props.steps.length === key + 1 ? true : false
      });
      this.refreshAnimation(key);
    }
  }
  getOutput() {
    const currentStepComponent = this[this.props.steps[this.state.currentStep].stepId];

    if (this.props.validate &&
      ((currentStepComponent.isValidated !== undefined && currentStepComponent.isValidated()) || currentStepComponent.isValidated === undefined) &&
      this.props.finishButtonClick !== undefined) {
        let output = { };
        this.props.steps.forEach(step => {
          output = {
            ...output,
            ...this[step.stepId].getFormOutput()
          };
        });
        return output;
      }
  }
  finishButtonClick() {    
    let output = this.getOutput();
    if (output) {
      output.doBatchSchedule = false;
      this.props.finishButtonClick(output);
    }
  }
  batchButtonClick() {    
    const output = this.getOutput();
    if (output) {      
      this.props.finishButtonClick(output);
    }
  }
  showBatchButton() {
    if (this[this.props.steps[1].stepId].state.doBatchSchedule) {
      this.setState({ batchButton: true });
    } else {
      this.setState({ batchButton: false });
    }
  }

  refreshAnimation(index) {
    var total = this.props.steps.length;
    var li_width = 100 / total;
    var total_steps = this.props.steps.length;
    var move_distance = this.refs.wizard.children[0].offsetWidth / total_steps;
    var index_temp = index;
    var vertical_level = 0;

    var mobile_device = window.innerWidth < 600 && total > 3;

    if (mobile_device) {
      move_distance = this.refs.wizard.children[0].offsetWidth / 2;
      index_temp = index % 2;
      li_width = 50;
    }

    this.setState({ width: li_width + "%" });

    var step_width = move_distance;
    move_distance = move_distance * index_temp;

    var current = index + 1;

    if (current === 1 || (mobile_device === true && index % 2 === 0)) {
      move_distance -= 8;
    } else if (
      current === total_steps ||
      (mobile_device === true && index % 2 === 1)
    ) {
      move_distance += 8;
    }

    if (mobile_device) {
      vertical_level = parseInt(index / 2, 10);
      vertical_level = vertical_level * 38;
    }
    var movingTabStyle = {
      width: step_width,
      transform:
        "translate3d(" + move_distance + "px, " + vertical_level + "px, 0)",
      transition: "all 0.5s cubic-bezier(0.29, 1.42, 0.79, 1)"
    };
    this.setState({ movingTabStyle: movingTabStyle });
  }

  renderWizardNavigation() {
    const { classes, title, subtitle, color, steps } = this.props;
    
    if(this.props.steps.length > 1) {
      return (
        <div className={classes.wizardNavigation}>
          <ul className={classes.nav}>
            {steps.map((prop, key) => {
              return (
                <li
                  className={classes.steps}
                  key={key}
                  style={{ width: this.state.width }}
                >
                  <a
                    className={classes.stepsAnchor}
                    onClick={() => this.navigationStepChange(key)}
                  >
                    {prop.stepName}
                  </a>
                </li>
              );
            })}
          </ul>
          <div
            className={classes.movingTab + " " + classes[color]}
            style={this.state.movingTabStyle}
          >
            {steps[this.state.currentStep].stepName}
          </div>
        </div>
      )
    } else {
      return ""
    }
  }

  render() {
    const { classes, title, subtitle, color, steps, finishButtonDisabled } = this.props;

    return (
      <div className={classes.wizardContainer} ref="wizard">
        <HeaderCard
            headerColor="orange"
            cardTitle={title}
            cardSubtitle={subtitle}
            content={
              <div>
                {this.renderWizardNavigation()}
                <div className={classes.content}>
                  {steps.map((prop, key) => {
                    const stepContentClasses = cx({
                      [classes.stepContentActive]: this.state.currentStep === key,
                      [classes.stepContent]: this.state.currentStep !== key
                    });
                    return (
                      <div className={stepContentClasses} key={key}>
                        {/* <prop.stepComponent innerRef={prop.stepId}/> */}
                        <prop.stepComponent
                          innerRef={node => (this[prop.stepId] = node)}
                          {...this.props.stepsProps}
                        />
                      </div>
                    );
                  })}
                </div>
                <div className={classes.footer}>
                  <div className={classes.left}>
                    {this.state.previousButton ? (
                      <Button
                        customClass={this.props.previousButtonClasses}
                        onClick={() => this.previousButtonClick()}
                      >
                        {this.props.previousButtonText}
                      </Button>
                    ) : null}
                    {this.state.cancelButton ? (
                      <Button
                        customClass={this.props.previousButtonClasses}
                        onClick={() => this.cancelButtonClick()}
                      >
                        {this.props.cancelButtonText}
                      </Button>
                    ) : null}
                  </div>
                  <div className={classes.right}>
                    {this.state.nextButton ? (
                      <Button
                        color="primary"
                        customClass={this.props.nextButtonClasses}
                        onClick={() => this.nextButtonClick()}
                      >
                        {this.props.nextButtonText}
                      </Button>
                    ) : null}
                    {this.state.batchButton && this.state.finishButton ? (
                        <Button
                          color="primary"
                          disabled={finishButtonDisabled}
                          customClass={this.props.finishButtonClasses}
                          onClick={() => this.batchButtonClick()}
                        >                          
                          {this.props.batchButtonText}
                        </Button>
                      ) : null}
                    {this.state.finishButton ? (
                      <Button
                        color="primary"
                        disabled={finishButtonDisabled}
                        customClass={this.finishButtonClasses}
                        onClick={() => this.finishButtonClick()}
                      >
                        {this.state.batchButton ? this.props.finishOneButtonText : this.props.finishButtonText}                        
                      </Button>
                    ) : null}
                  </div>
                  <div className={classes.clearfix} />
                </div>
              </div>
            }
          />
      </div>
    );
  }
}

Wizard.defaultProps = {
  color: "primary",
  title: "Here should go your title",
  subtitle: "And this would be your subtitle",
  previousButtonText: "Previous",
  previousButtonClasses: "",
  nextButtonClasses: "",
  nextButtonText: "Next",
  cancelButtonClasses: "",
  cancelButtonText: "Cancel",
  finishButtonClasses: "",
  finishButtonText: "Finish"
};

Wizard.propTypes = {
  classes: PropTypes.object.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      stepName: PropTypes.string.isRequired,
      stepComponent: PropTypes.func.isRequired,
      stepId: PropTypes.string.isRequired
    })
  ).isRequired,
  color: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose"
  ]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  previousButtonClasses: PropTypes.string,
  previousButtonText: PropTypes.string,
  nextButtonClasses: PropTypes.string,
  nextButtonText: PropTypes.string,
  finishButtonClasses: PropTypes.string,
  finishButtonText: PropTypes.string,
  finishButtonClick: PropTypes.func,
  validate: PropTypes.bool
};

export default withStyles(wizardStyle)(Wizard);
