import React, { Component } from 'react';
import { connect } from 'react-redux';

import Log from '../logging/Log.jsx';

export default function (ComposedComponent) {
  class UnAuthentication extends Component {

    UNSAFE_componentWillMount() {
      if (this.props.authenticationState && this.props.authenticationState.authenticated) {
        Log.info("UnAuthentication HOC recognized that user is signed in - will redirect");
        this.props.history.push('/');
      }
    }

    UNSAFE_componentWillUpdate(nextProps) {
      if (nextProps.authenticationState && nextProps.authenticationState.authenticated) {
        Log.info("UnAuthentication HOC recognized that user is signed in - will redirect");
        this.props.history.push('/');
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps( { authenticationState } ) {
    Log.info("Mapping", authenticationState);
    return { authenticationState };
  }

  return connect(mapStateToProps)(UnAuthentication);
}