const deploymentApiMapping = {
  // Production
  "ne-portal-app.azurewebsites.net": "https://ne-cloud-api.azurewebsites.net/",
  "portal.neuroelectrics.com": "https://ne-cloud-api.azurewebsites.net/",

  // Staging
  "ne-portal-app-staging.azurewebsites.net":
    "https://ne-cloud-api-staging.azurewebsites.net/",
  "portal-pre.neuroelectrics.com":
    "https://ne-cloud-api-staging.azurewebsites.net/",

  // Development
  "ne-portal-app-development.azurewebsites.net":
    "https://ne-cloud-api-development.azurewebsites.net/",

  // Dev
  "ne-portal-app-dev.azurewebsites.net":
    "https://ne-cloud-api-dev.azurewebsites.net/"
}

let backendHost
const hostname = window && window.location && window.location.hostname

var isLocal

if (deploymentApiMapping.hasOwnProperty(hostname)) {
  backendHost = deploymentApiMapping[hostname]
  isLocal = false
} else {
  //backendHost = "https://ne-cloud-api-staging.azurewebsites.net/"
  backendHost = "https://ne-cloud-api-dev.azurewebsites.net/"
  // localStorage.setItem('debug', 'ne-portal-debug:*');
  isLocal = true
}

export const localDeployment = isLocal

/*
if (hostname === 'ne-portal-app.azurewebsites.net') {
  backendHost = 'https://ne-cloud-api-dev.azurewebsites.net/'
} else if (hostname === 'ne-portal-app-staging.azurewebsites.net') {
  backendHost = 'https://ne-cloud-api-staging.azurewebsites.net/'
} else {
  //backendHost = 'http://' + hostname + ':5000/';
  backendHost = 'https://ne-cloud-api-dev.azurewebsites.net/'
}
*/

export const BACKEND_ROOT_URL = backendHost

export const AUTHMODULE_URL = `auth/`
export const TREATMENTS_URL = `treatment/`
