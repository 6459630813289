import React, { Component } from 'react';
import Log from '../logging/Log.jsx';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Snackbar from "components/Snackbar/Snackbar.jsx";

import { pushNotification, notificationHidden } from '../actions/index';

export default function (ComposedComponent) {
  class Notifiable extends Component {

    constructor(props) {
      super(props);
      this.renderNotification = this.renderNotification.bind(this);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
      if (this.props.show && !prevProps.show) {
        setTimeout(
          function() {
            this.props.notificationHidden();
          }.bind(this),
          5000
        );
      }
    }

    renderNotification() {
      Log.info("Rendering notification with props:", this.props)
      return (
        <Snackbar
          message={this.props.message}
          open={this.props.show}
          closeNotification={() => this.props.notificationHidden()}
        />
      )
    }

    render() {
      return (
        <div>
          <ComposedComponent {...this.props} />
          {this.renderNotification()}
        </div>
      );
    }
  }

  function mapStateToProps( { notification } ) {
    Log.info("Mapping", notification);
    return { show: notification.show, message: notification.message };
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({pushNotification, notificationHidden}, dispatch);
  }

  return connect(mapStateToProps, mapDispatchToProps)(Notifiable);
}