import React from "react";
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Log from '../logging/Log.jsx';

// core components
import Wizard from "molecules/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";

import { patientCodesSelector, patientByCodeSelector } from "../selectors/patientSelectors";

import LayoutEditPatientBasicData from "../molecules/LayoutEditPatientBasicData.jsx"

import { addPatient, editPatient, pushNotification } from '../actions/index';

import {DataSubmissionStates} from '../reducers/utils.js'

class PatientEditor extends React.Component {

  constructor(props) {
    Log.info("Creating PatientEditor with props:", props);
    super(props);
    this.state = {
      submitFailed: false
    };

    this.finishWizard = this.finishWizard.bind(this);
    this.closeWizard = this.closeWizard.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    Log.info("Patient submission state:", this.props.patientSubmissionState)

    if (prevProps.patientSubmissionState === DataSubmissionStates.POSTING) {
      if (this.props.patientSubmissionState === DataSubmissionStates.POSTED) {
        this.closeWizard();
      }
      else if (this.props.patientSubmissionState === DataSubmissionStates.FAILED) {
        this.props.pushNotification(this.props.errorMessage);
      }
    }
  }

  finishWizard(result) {
    if (this.props.patient) this.props.editPatient(Object.assign(this.props.patient, result))
    else this.props.addPatient(result.code, result.lang);
  }

  closeWizard() {
    this.props.onClose();
  }

  render() {
    let title
    let lang
    let patientCode
    if (this.props.patient) {
      patientCode = this.props.patient.code
      lang = this.props.patient.lang
      title = "Edit subject " + patientCode
    }
    else {
      patientCode = ""
      title = "Add new subject"
    }

    return (
      <GridContainer justify="center">
        <ItemGrid xs={12} sm={8}>
          <Wizard
            validate={true}
            steps={[
              { stepName: "Basic", stepComponent: LayoutEditPatientBasicData, stepId: "basic" }
            ]} // TODO: Props separately per step - and naming reflecting parameter and callback naming
            title={title}
            subtitle={""}
            finishButtonClick={(result) => this.finishWizard(result)}
            cancelButtonClick={() => this.closeWizard()}
            color="primary"
            stepsProps={{
              code: patientCode,
              lang: lang,
              languages: this.props.languages,
              existingCodes: this.props.patientCodes
            }}
            finishButtonText="Save"
          />
        </ItemGrid>
      </GridContainer>
    );
  }
}

PatientEditor.propTypes = {
  code: PropTypes.string
};

function mapStateToProps(state, props) {
  const patientCodes = patientCodesSelector(state);

  const patient = patientByCodeSelector(state, props.code);

  let patientSubmissionState
  let errorMessage = null
  if ( !state.patients.isFetching && !state.patients.posted && state.patients.error === null ) patientSubmissionState = DataSubmissionStates.IDLE;
  else if ( state.patients.isFetching && !state.patients.posted ) patientSubmissionState = DataSubmissionStates.POSTING;
  else if ( !state.patients.isFetching && state.patients.posted ) patientSubmissionState = DataSubmissionStates.POSTED;
  else if ( !state.patients.isFetching && state.patients.error !== null ) {
    patientSubmissionState = DataSubmissionStates.FAILED;
    errorMessage = state.patients.error.data.message;
  }

  return { patient, patientCodes, patientSubmissionState, errorMessage, languages: state.languages };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({addPatient, editPatient, pushNotification}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientEditor);

