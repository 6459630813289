import React from "react";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Log from '../logging/Log.jsx';

// core components
import Wizard from "molecules/Wizard.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import ItemGrid from "components/Grid/ItemGrid.jsx";
import ModalConfirmation from "../molecules/ModalConfirmation";

import LayoutScheduleTreatmentSessions from "../molecules/LayoutScheduleTreatmentSessions.jsx";
import LayoutSelectSessionProtocol from "../molecules/LayoutSelectSessionProtocol.jsx";

import {DataSubmissionStates} from '../reducers/utils.js'

import { getSessionProtocols, postSessionProtocol, getDeviceProtocol, postDeviceProtocol, 
  postTreatmentSession, pushNotification, patchDeviceProtocol, clearDeviceProtocol, getProtocolSchema, clearSchemaInfo } from '../actions/index';

class TreatmentSchedulerSimple extends React.Component {

  constructor(props) {
    Log.info("Creating TreatmentSchedulerSimple with props:", props);
    super(props);
    this.state = {
      awaitingPatch: false,
      wizardResult: null,
      modal: false,
      batchSchedulingModal: false,
      batchConfirmation: false,
      disableFinishButton: false
    };
    this.finishWizard = this.finishWizard.bind(this);
    this.closeWizard = this.closeWizard.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.ModalUnfullData = this.ModalUnfullData.bind(this);
    this.ModalBatchScheduling = this.ModalBatchScheduling.bind(this);
    this.confirmBatch = this.confirmBatch.bind(this);
  }
  
  componentDidUpdate(prevProps, prevState, snapshot) {
    Log.info("Treatment session submission state:", this.props.treatmentSessionSubmissionState)

    const result = this.state.wizardResult;

    if (!prevProps.patients.fullData && this.props.patients.fullData && this.state.modal) {
      this.hideAlert();
      this.props.postTreatmentSession(this.props.patient.treatments[0].id, result.sessionProtocolId, result.date, result.periodStart, result.periodEnd, result.doBatchSchedule);
      this.setState({ disableFinishButton: true });
    }

    if (!prevState.batchConfirmation && this.state.batchConfirmation && this.state.batchSchedulingModal) {
      if (this.props.patients.fullData) {
        this.hideAlert();
        this.props.postTreatmentSession(this.props.patient.treatments[0].id, result.sessionProtocolId, result.date, result.periodStart, result.periodEnd, result.doBatchSchedule);
        this.setState({ disableFinishButton: true });
      } else {
        this.setState({ modal: true, batchSchedulingModal: false });
      }
    }

    if (prevProps.treatmentSessionSubmissionState === DataSubmissionStates.POSTING) {
      if (this.props.treatmentSessionSubmissionState === DataSubmissionStates.POSTED) {
        this.closeWizard();
      }
      else if (this.props.treatmentSessionSubmissionState === DataSubmissionStates.FAILED) {
        this.props.pushNotification(this.props.errorMessage);
      }
    }

    if(this.state.awaitingPatch === true) {
      if (prevProps.deviceProtocolSubmissionState === DataSubmissionStates.POSTING) {
        if (this.props.deviceProtocolSubmissionState === DataSubmissionStates.POSTED) {
          this.props.postTreatmentSession(this.props.patient.treatments[0].id, result.sessionProtocolId, result.date, result.periodStart, result.periodEnd, result.doBatchSchedule);
          this.setState({awaitingPatch: false, disableFinishButton: true});
        }
        else if (this.props.deviceProtocolSubmissionState === DataSubmissionStates.FAILED) {
          this.props.pushNotification(this.props.errorMessage);
        }
      }
    }
  }

  hideAlert() {
    this.setState({
      modal: false,
      batchSchedulingModal: false,
    })
  }

  ModalUnfullData() {
    if (this.state.modal) {      
      return (
        <ModalConfirmation
          content={
            <div>
              <p>Scheduling your treatment.</p>
              <p>Please, hold on for a few seconds...</p>
            </div>
          }
        />
      )
    } else {
      return ""
    }
  }

  ModalBatchScheduling() {    
    if (this.state.batchSchedulingModal) {
      return (
        <ModalConfirmation
          content={
            <div>
              <p>Schedule a treatment of {this.props.schema.dates.length} sessions?</p> 
              <p>It will last from {this.props.schema.dates[0]} to {this.props.schema.dates[this.props.schema.dates.length-1]}.</p>
            </div>
          }
          confirmationText="Confirm"
          cancelText="Cancel"
          onConfirm={() => this.confirmBatch()}
          onCancel={() => this.hideAlert()}
        />
      )
    } else {
      return ""
    }
  }

  confirmBatch() {
    this.setState({batchConfirmation: true})
  }

  async finishWizard(result) {
    Log.info("Finishing wizard with result:", result);
    if (result.doBatchSchedule && !this.state.batchConfirmation) {
      this.props.clearSchemaInfo();
      await this.props.getProtocolSchema(result.schemaId, result.date);
      this.setState({ batchSchedulingModal: true, wizardResult: result });
    } else if (!this.props.patients.fullData) {
      this.setState({ modal: true, wizardResult: result });
    } else if (result.deviceProtocolPatch.patch) {
      this.props.patchDeviceProtocol(result.deviceProtocolPatch.deviceProtocolId, result.deviceProtocolPatch.patch)
      this.setState({ awaitingPatch: true, wizardResult: result });
    }    
    else {
      this.props.postTreatmentSession(this.props.patient.treatments[0].id, result.sessionProtocolId, result.date, result.periodStart, result.periodEnd, result.doBatchSchedule);
      this.setState({ disableFinishButton: true });
    }
  }

  closeWizard() {
    this.props.onClose();
  }

  render() {
    return (
        <GridContainer justify="center">
          <ItemGrid xs={12} sm={8}>            
            <this.ModalUnfullData/>
            <this.ModalBatchScheduling/>
            <Wizard
              validate={true}
              steps={[
                { stepName: "Schedule", stepComponent: LayoutScheduleTreatmentSessions, stepId: "schedule" },
                { stepName: "Protocol", stepComponent: LayoutSelectSessionProtocol, stepId: "protocol" }
              ]}
              title="Treatment Scheduler"
              subtitle={"For " + this.props.patient.code}
              finishButtonClick={(result) => this.finishWizard(result)}
              cancelButtonClick={() => this.closeWizard()}
              finishButtonDisabled={this.state.disableFinishButton}
              color="primary"
              stepsProps={{
                patient: this.props.patient,
                dates: this.props.dates,
                getSessionProtocols: this.props.getSessionProtocols,
                postSessionProtocol: this.props.postSessionProtocol,
                getDeviceProtocol: this.props.getDeviceProtocol,
                postDeviceProtocol: this.props.postDeviceProtocol,
                sessionProtocols: this.props.sessionProtocols,
                deviceProtocols: this.props.deviceProtocols,
                patchDeviceProtocol: this.props.patchDeviceProtocol,
                clearDeviceProtocol: this.props.clearDeviceProtocol
              }}
              finishButtonText="Schedule"
              finishOneButtonText="Schedule Single"
              batchButtonText="Schedule All Sessions"
            />
          </ItemGrid>
        </GridContainer>
    );
  }
}

function mapStateToProps( {sessionProtocols, deviceProtocols, schema, sessions, patients} ) {
  let treatmentSessionSubmissionState
  let deviceProtocolSubmissionState
  let errorMessage = null
  if ( !sessions.isRequesting && !sessions.posted && sessions.error === null ) treatmentSessionSubmissionState = DataSubmissionStates.IDLE;
  else if ( sessions.isRequesting && !sessions.posted ) treatmentSessionSubmissionState = DataSubmissionStates.POSTING;
  else if ( !sessions.isRequesting && sessions.posted ) treatmentSessionSubmissionState = DataSubmissionStates.POSTED;
  else if ( !sessions.isRequesting && sessions.error !== null ) {
    treatmentSessionSubmissionState = DataSubmissionStates.FAILED;
    errorMessage = sessions.error.data.message;
  }
  if ( !deviceProtocols.isRequesting && !deviceProtocols.posted && deviceProtocols.error === null ) deviceProtocolSubmissionState = DataSubmissionStates.IDLE;
  else if ( deviceProtocols.isRequesting && !deviceProtocols.posted ) deviceProtocolSubmissionState = DataSubmissionStates.POSTING;
  else if ( !deviceProtocols.isRequesting && deviceProtocols.posted ) deviceProtocolSubmissionState = DataSubmissionStates.POSTED;
  else if ( !deviceProtocols.isRequesting && deviceProtocols.error !== null ) {
    deviceProtocolSubmissionState = DataSubmissionStates.FAILED;
    errorMessage = deviceProtocols.error.data.message;
  }
  return { sessionProtocols, deviceProtocols, schema, patients, treatmentSessionSubmissionState, deviceProtocolSubmissionState, errorMessage };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({getSessionProtocols, postSessionProtocol, getDeviceProtocol, postDeviceProtocol, 
    postTreatmentSession, pushNotification, patchDeviceProtocol, clearDeviceProtocol,getProtocolSchema, clearSchemaInfo}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(TreatmentSchedulerSimple);

