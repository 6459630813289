import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Log from '../logging/Log.jsx';

import { getLanguages } from '../actions/index';

export default function (ComposedComponent) {
  class PortalZoneComponent extends Component {

    UNSAFE_componentWillMount() {
      if (this.props.authenticationState && (!this.props.authenticationState.authenticated || this.props.authenticationState.token === null)) {
        Log.info("Authentication HOC recognized that user is not signed in - will redirect");
        this.props.history.push('/pages/sign_in');
      }
    }

    UNSAFE_componentWillUpdate(nextProps) {
      if (nextProps.authenticationState && (!nextProps.authenticationState.authenticated || nextProps.authenticationState.token === null)) {
          Log.info("Authentication HOC recognized that user is not signed in - will redirect");
          this.props.history.push('/pages/sign_in');
      }
    }

    render() {
      if (Object.keys(this.props.languages.byId).length === 0 && this.props.languages.isRequesting === false) {
        Log.info("Will update metadata");
        this.props.getLanguages();
      }

      return <ComposedComponent {...this.props} />;
    }
  }

  function mapStateToProps( { authenticationState, languages } ) {
    Log.info("Mapping", authenticationState);
    return { authenticationState, languages };
  }

  function mapDispatchToProps(dispatch) {
    return bindActionCreators({getLanguages}, dispatch);
  }

  return connect(mapStateToProps, mapDispatchToProps)(PortalZoneComponent);
}