import {  createSelector } from 'reselect'

const _getSessionsByPatient = (state, patient) => {
  if(!patient || !patient.treatments) return [];
  return patient.treatments
    .map(treatment => treatment.sessions.map(session => { return {id: session.id, treatmentId: treatment.id}; }))
    .reduce((flat,array) => flat.concat(array), [])
    .map(object => { let session = state.sessions.byId[object.id]; return session ? {treatmentId: object.treatmentId, ...session} : undefined; })
    .filter(session => session !== undefined).filter(session => session.deleted !== true);
}

export const sessionsByPatientSelector = createSelector(
  [_getSessionsByPatient],
  (sessions) => (sessions === null ? [] : sessions)
)