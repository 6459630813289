// ##############################
// // // Main Table HeaderLinks styles
// #############################
  
  const mainTableHeaderLinksStyle = theme => ({    
    search: {
      [theme.breakpoints.down("sm")]: {
        margin: "10px 15px",
        float: "none !important",
        paddingTop: "1px",
        paddingBottom: "1px",
        padding: "10px 15px",
        width: "auto"
      }
    },
    searchInput: {
      paddingTop: "2px"
    },    
    searchButton: {
      [theme.breakpoints.down("sm")]: {
        top: "-50px !important",
        marginRight: "38px",
        float: "right"
      }
    },
    top: {
      zIndex: "4"
    },
    searchIcon: {
      width: "17px",
      zIndex: "4"
    },    
    searchInTable: {
      marginRight: "20px", 
      display: "flex",
      float: "right"
    },
    backButton: {
      margin: "20px 20px 0 0",
      width: "auto" 
    },
    searchInput: {
      display: "inline-block"
    }
  });
  
  export default mainTableHeaderLinksStyle;
  