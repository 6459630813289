import React from "react";
import PropTypes from "prop-types";

import withStyles from "material-ui/styles/withStyles";
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx";
import Button from "components/CustomButtons/Button.jsx";


class AccessLevelButton extends React.Component {
 render() {
  const { message } = this.props;
   return (
    <Button round size="sm">
     {message}
    </Button>
   )
 }
}

AccessLevelButton.propTypes = {
  message: PropTypes.string.isRequired,
}

export default withStyles(buttonsStyle)(AccessLevelButton);