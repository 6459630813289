import React from "react";

import SvgIcon from 'material-ui/SvgIcon';

import SponstimElectrodeImg from "../assets/img/electrode_sponstim_simple.svg";
import NGPistimElectrodeImg from "../assets/img/electrode_ngpistim_simple.svg";

export function SponstimIcon(props) {
    return (
        <img src={SponstimElectrodeImg} {...props}></img>
    )
}

export function NGPistimIcon(props) {
    return (
        <img src={NGPistimElectrodeImg} {...props}></img>
    )
}