import React from "react";

import notificationsStyle from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.jsx";

import Dialog from "material-ui/Dialog";
import DialogTitle from "material-ui/Dialog/DialogTitle";
import DialogContent from "material-ui/Dialog/DialogContent";
import DialogActions from "material-ui/Dialog/DialogActions";
import IconButton from "material-ui/IconButton";
import Close from "@material-ui/icons/Close";
// import Button from "material-ui/Button/Button"
import Button from "../components/CustomButtons/Button.jsx"
import Slide from "material-ui/transitions/Slide";

import { withStyles } from 'material-ui';


function Transition(props) {
  return <Slide direction="down" {...props} />;
}


class ModalConfirmation extends React.Component {

  CloseX(props) {
    const { classes, content, cancelText, dangerText, confirmationText, onConfirm, onCancel, onDanger, xClose } = props;
    if(xClose && onCancel) {
      return (
        <IconButton
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={onCancel}
        >                            
          <Close className={classes.modalClose} />
        </IconButton>
      )
    } else {
      return "";
    }
  }

  CancelButton(props) {
    const { classes, content, cancelText, dangerText, confirmationText, onConfirm, onCancel, onDanger, xClose } = props;
    if(onCancel && !xClose) {
      return (
        <Button
          onClick={onCancel}
          color="simple"
          customClass={classes.modalSmallFooterFirstButton}
        >
          {cancelText}
      </Button>
      )
    } else {
      return "";
    }
  }

  ConfirmButton(props) {
    const { classes, content, cancelText, dangerText, confirmationText, onConfirm, onCancel, onDanger, xClose } = props;
    if(onConfirm) {
      return (
        <Button
          onClick={onConfirm}
          color="primary"
          customClass={
            classes.modalSmallFooterFirstButton +
            " " +
            classes.modalSmallFooterSecondButton
          }
        >
          {confirmationText}
        </Button>
      )
    } else {
      return "";
    }
  }

  DangerButton(props) {
    const { classes, content, cancelText, dangerText, confirmationText, onConfirm, onCancel, onDanger, xClose } = props;
    if(onDanger) {
      return (
        <Button
          onClick={onDanger}
          color="danger"
          customClass={classes.modalSmallFooterFirstButton}
        >
          {dangerText}
        </Button>
      )
    } else {
      return "";
    }
  }

  render() {
    const { classes, content, cancelText, dangerText, confirmationText, onConfirm, onCancel, onDanger } = this.props;

    return (
      <Dialog
        classes={{
          root: classes.center,
          paper: classes.modal + " " + classes.modalMedium
        }}
        transition={Transition}
        keepMounted
        open={true}
        aria-labelledby="small-modal-slide-title"
        aria-describedby="small-modal-slide-description"
        >
        <DialogTitle
          id="small-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <this.CloseX {...this.props}/>
        </DialogTitle>
        <DialogContent
          id="small-modal-slide-description"
          className={
            classes.modalBody + " " + classes.modalSmallBody
          }
        >
          <h5>{content}</h5>
        </DialogContent>
        <DialogActions
          className={
            classes.modalFooter +
            " " +
            classes.modalFooterCenter
          }
        >
          <this.DangerButton {...this.props}/>
          <this.ConfirmButton {...this.props}/>
          <this.CancelButton {...this.props}/>
        </DialogActions>
      </Dialog>
    )
  }

}


export default withStyles(notificationsStyle)(ModalConfirmation);