import {  createSelector } from 'reselect'

const getPatientByCode = (state, code) => state.patients.data.filter(patient => patient.code === code)[0]

const getPatientsCodes = (state) => state.patients.data.map(patient => (patient.code))

export const patientByCodeSelector = createSelector(
  [getPatientByCode],
  (patient) => (patient === null ? {} : patient)
)

export const patientCodesSelector = createSelector(
  [getPatientsCodes],
  (patientCodes) => (patientCodes)
)